<template>
  <div
    :ref="id"
    :class="['generic-dropdown-container generic-form__component generic-select', computedClasses]"
  >
    <legend
      v-if="inputLabel"
      :id="`generic-select__label--${id}`"
      :class="`generic-select__label generic-select__label--${id}`"
      :for="`select-${id}`"
    >
      <span
        >{{ inputLabel
        }}<span v-if="inputLabel && required" class="required" aria-hidden="true">*</span></span
      >
    </legend>

    <div class="generic-select__dropdown">
      <div v-if="readonly" class="read-only">{{ defaultValue }}</div>

      <template v-else>
        <button
          :id="`select-${id}`"
          ref="combobox"
          role="combobox"
          type="input"
          class="generic-select__combobox"
          :aria-controls="`generic-select__listbox--${id}`"
          :aria-expanded="expanded"
          aria-haspopup="listbox"
          :aria-describedby="descriptionId"
          :aria-label="inputLabel"
          :aria-activedescendant="activeDescendant"
          :aria-invalid="invalid"
          :aria-required="required"
          :required="required"
          :disabled="disabled"
          @focus="handleFocus($event, true)"
          @blur="handleFocus($event, false)"
          @click.prevent.self="handleExpand($event, true)"
          @keydown.space.prevent="handleExpand($event, true)"
          @keydown.enter.prevent="handleExpand($event, true)"
          @keydown.esc.prevent="handleExpand($event, false)"
          @keydown.down="navigateList"
          @keydown.up="navigateList"
          v-html="getSelectedOption"
        ></button>

        <div
          :id="`generic-select__listbox--${id}`"
          class="generic-select__listbox"
          role="listbox"
          :aria-labelledby="`generic-select__label--${id}`"
          tabindex="-1"
          @click.stop.prevent="handleOnChange($event, false)"
        >
          <div class="generic-select__listbox-wrapper">
            <slot></slot>
          </div>
        </div>
      </template>
    </div>

    <input type="hidden" :name="inputName" :value="selectedOptions" />
  </div>
</template>

<script>
export default {
  name: 'GenericDropdownContainer',
};
</script>

<script setup>
import { ref, computed, onMounted } from 'vue';

const emit = defineEmits(['handleValidation', 'handleInput']);

const combobox = ref(null);

const props = defineProps({
  id: {
    type: String,
    default: null,
  },
  inputName: {
    type: String,
    default: null,
  },
  inputLabel: {
    type: String,
    default: '',
  },
  defaultValue: {
    type: String,
    default: '',
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: true,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  descriptionId: {
    type: [String, Object],
    default: null,
  },
  customClass: {
    type: String,
    default: '',
  },
  hasErrors: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
  selectedOptions: {
    type: Array,
    default: () => [],
  },
  multiSelect: {
    type: Boolean,
    default: false,
  },
  customSelectedLabel: {
    type: String,
    default: '',
  },
});

const expanded = ref(false);
const activeDescendant = ref(undefined);
const focus = ref(false);
const hadFocus = ref(false);

const computedClasses = computed(() => {
  return {
    'generic--active': focus.value,
    'generic--disabled': props.disabled,
    'generic--readonly': props.readonly,
    'generic--has-errors': props.invalid || props.hasErrors,
    'generic--raised': props.placeholder || hasSelectedOption.value,
    'generic-select--not-selected': !hasSelectedOption.value,
  };
});
const hasSelectedOption = computed(() => {
  return props.selectedOptions.length
    ? props.selectedOptions.some((option) => option !== null && option !== '')
    : false;
});
const getSelectedOption = computed(() => {
  return hasSelectedOption.value && !expanded.value
    ? props.customSelectedLabel
      ? props.customSelectedLabel
      : props.selectedOptions.sort().join(', ')
    : hasSelectedOption.value && expanded.value
    ? ''
    : props.placeholder
    ? `<span class="generic-select__placeholder">${props.placeholder}</span>`
    : '';
});

function setGridStyle() {
  if (!combobox.value) return '';
  setTimeout(() => {
    const hasMultipleChildren = combobox.value.children.length > 1;
    if (hasMultipleChildren) {
      combobox.value.style.gridTemplateColumns = '1.625rem auto';
    } else {
      combobox.value.style.gridTemplateColumns = 'auto';
    }
  }, 10);
  setTimeout(() => {
    Object.values(combobox.value.children).forEach((child) => (child.style.opacity = '1'));
  }, 200);
}

async function handleExpand(event, expand) {
  const redundantBlur =
    !expand && document.activeElement === event.target && event.key !== 'Escape';
  if (redundantBlur) return;

  const isKeyboardSelectAction =
    expanded.value && event instanceof KeyboardEvent && event.key !== 'Escape';
  const isKeyboardExpandAction = !expanded.value && event instanceof KeyboardEvent;
  const { listBoxWrapper, firstOption, activeOption, focusedOption } = getOptions(event);

  if (isKeyboardSelectAction) {
    handleOnChange(event, true);
    if (!props.multiSelect) {
      expanded.value = false;
      focusedOption?.classList.remove('-focused');
    }
  } else {
    expanded.value = expand;
    if (expand) {
      event.target.focus();
      await focusSelectedOption(event);
      listBoxWrapper.scrollTop = activeOption
        ? activeOption.offsetTop - 15
        : firstOption.offsetTop - 15;
    } else {
      focusedOption?.classList.remove('-focused');
      setTimeout(() => {
        Object.values(combobox.value.children).forEach((child) => (child.style.opacity = '1'));
      }, 10);
    }
  }
}

function handleFocus(event, focused) {
  hadFocus.value = true;

  if (focused) {
    focus.value = true;
  } else {
    if (isMultiSelectClick(event)) {
      focus.value = true;
      event.target.focus();
    } else {
      focus.value = false;
      emit('handleValidation');
      setTimeout(() => {
        handleExpand(event, false);
      }, 250);
    }
  }
}

function isMultiSelectClick(event) {
  const listboxId = event.target.parentElement
    .querySelector('.generic-select__listbox')
    .getAttribute('id');
  const clickTargetId = event.relatedTarget?.getAttribute('id');
  return props.multiSelect && clickTargetId === listboxId;
}

function focusSelectedOption(event, target) {
  const { focusedOption, firstOption, activeOption } = getOptions(event);
  focusedOption?.classList.remove('-focused');
  if (target) {
    target.classList.add('-focused');
    setActiveDescendant(target);
  } else if (activeOption) {
    activeOption.classList.add('-focused');
    setActiveDescendant(activeOption);
  } else {
    firstOption.classList.add('-focused');
    setActiveDescendant(firstOption);
  }
}

function setActiveDescendant(target) {
  const optionId = target.getAttribute('id');
  activeDescendant.value = optionId;
}

function navigateList(event) {
  if (!expanded.value) return;
  event.preventDefault();
  const pressedKey = event.key;
  const { firstOption, lastOption, focusedOption, listBoxWrapper, allChildren } = getOptions(event);
  const isFirstOption = focusedOption === allChildren[0];
  const isLastOption = focusedOption === allChildren[allChildren.length - 1];
  const nextWrapper = focusedOption?.nextElementSibling;
  const previousWrapper = focusedOption?.previousElementSibling;

  if (!focusedOption) {
    focusSelectedOption(event, firstOption);
    listBoxWrapper.scrollTop = 15;
    return;
  }

  switch (pressedKey) {
    case 'ArrowDown':
      if (isLastOption) {
        focusSelectedOption(event, firstOption);
        listBoxWrapper.scrollTop = 0;
      } else {
        focusSelectedOption(event, nextWrapper);
        listBoxWrapper.scrollTop += focusedOption.offsetHeight;
      }
      break;
    case 'ArrowUp':
      if (isFirstOption) {
        focusSelectedOption(event, lastOption);
        listBoxWrapper.scrollTop = lastOption.offsetTop;
      } else {
        focusSelectedOption(event, previousWrapper);
        listBoxWrapper.scrollTop -= focusedOption.offsetHeight;
      }
      break;
    default:
      focusSelectedOption(event, firstOption);
      listBoxWrapper.scrollTop = 0;
      break;
  }
}

function collapseFromListbox(event) {
  const focusedOption = event.target.querySelector('.-focused');
  focusedOption?.classList.remove('-focused');
  expanded.value = false;
}

function handleOnChange(event, isKeyboardEvent) {
  if (event instanceof MouseEvent && event.target.classList.contains('generic-select__listbox')) {
    collapseFromListbox(event);
  } else {
    emit('handleInput', event, isKeyboardEvent);
  }
}

function getOptions(event) {
  const targetElement = event.target;
  const regex = /select-(.*)/;
  const id = regex.exec(targetElement.id);
  const listBox = document.getElementById(`generic-select__listbox--${id[1]}`);
  const listBoxWrapper = listBox.querySelector('.generic-select__listbox-wrapper');
  const allChildren = listBoxWrapper.children;
  const firstOption = allChildren[0];
  const lastOption = allChildren[allChildren.length - 1];
  const activeOption = listBoxWrapper.querySelector('.-selected');
  const focusedOption = listBoxWrapper.querySelector('.-focused');
  return {
    listBox,
    listBoxWrapper,
    allChildren,
    firstOption,
    lastOption,
    activeOption,
    focusedOption,
  };
}

onMounted(() => {
  setGridStyle();
});

defineExpose({
  combobox,
  props,
  expanded,
  activeDescendant,
  focus,
  hadFocus,
  computedClasses,
  hasSelectedOption,
  getSelectedOption,
  handleExpand,
  handleFocus,
  isMultiSelectClick,
  focusSelectedOption,
  setActiveDescendant,
  navigateList,
  handleOnChange,
  getOptions,
  collapseFromListbox,
  setGridStyle,
});
</script>
