<template>
  <div :class="{ 'oap-stories__circle--is-mex': isMex }">
    <svg
      v-if="renderOuterCircle"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="-2.5 -2.5 49 49"
    >
      <circle
        fill="none"
        stroke="none"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        cx="22"
        cy="22"
        r="22"
      />
    </svg>
    <img
      :alt="imageAlt"
      :data-src="imageSrc"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM88x8AAp0BzdNtlUkAAAAASUVORK5CYII="
      class="oap-card__amp-image lazyload"
    />
    <span v-if="username" class="oap-stories__circle-username">{{ username }}</span>
  </div>
</template>

<script>
export default {
  name: 'OapStoriesCircle',
  props: {
    username: {
      type: String,
      default: '',
    },
    imageAlt: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    renderOuterCircle: {
      type: Boolean,
      default: true,
    },
    isMex: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
