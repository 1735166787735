<template>
  <div class="haircolorDiagForm__container">
    <div class="haircolorDiagForm__nav">
      <button ref="backButton" class="haircolorDiagForm__backButton" @click="back()">
        <span class="is-sr-only">{{ dictionary.backLabel }}</span>
        {{ dictionary.backLabel }}
      </button>
    </div>

    <div class="haircolorDiagForm__grid">
      <div class="haircolorDiagForm__col">
        <transition name="haircolorDiagSwipe" mode="out-in">
          <ul :key="`card-${getCurrentQuestion.name}`" class="haircolorDiagForm__answers">
            <li
              v-for="answer of getCurrentQuestion.answers"
              :key="answer"
              class="haircolorDiagRadio__container"
              :class="{ '-noTip': !answers[answer].tip }"
            >
              <input
                :id="answers[answer].id"
                v-model="formData[getCurrentQuestion.id]"
                class="haircolorDiagRadio__input haircolorDiagForm__input is-sr-only"
                type="radio"
                :value="answers[answer].id"
              />
              <div class="haircolorDiagRadio__control">
                <img
                  class="haircolorDiagRadio__thumbnail lazyload"
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                  :data-src="answers[answer].image.fileName"
                  :alt="answers[answer].image.alt"
                />
                <label class="haircolorDiagRadio__label" :for="answers[answer].id">
                  <span>{{ answers[answer].label }}</span>
                </label>
              </div>

              <haircolor-diag-tip v-if="answers[answer].tip" :tip="answers[answer].tip" />
            </li>
          </ul>
        </transition>
      </div>
      <div class="haircolorDiagForm__col">
        <div class="haircolorDiagForm__hero">
          <transition name="haircolorDiagHero" mode="out-in">
            <div :key="`heroText-${getCurrentQuestion.name}`">
              <h1 class="haircolorDiagForm__title">{{ getCurrentQuestion.title }}</h1>
              <p class="haircolorDiagForm__subtitle">
                {{ getCurrentQuestion.subtitle || dictionary.subtitle }}
              </p>
              <button
                class="haircolorDiagForm__nextButton -isDesktop"
                type="button"
                :disabled="!isFilled || shouldAbort"
                @click="next()"
              >
                {{ getNextButtonLabel }}
              </button>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <button
      class="haircolorDiagForm__nextButton -isMobile"
      :disabled="!isFilled || shouldAbort"
      @click="next()"
    >
      {{ getNextButtonLabel }}
    </button>
  </div>
</template>

<script>
import haircolorDiagTip from './haircolorDiagTip.vue';
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

export default {
  name: 'HaircolorDiag',

  components: {
    haircolorDiagTip,
  },

  props: {
    introUrl: { type: String, required: true },
    dictionary: { type: Object, required: true },
    questions: { type: Object, required: true },
    answers: { type: Object, required: true },
  },

  data() {
    return {
      previousQuestionId: null,
      currentQuestionId: null,
      formData: {},
      history: [],
    };
  },

  computed: {
    getNextButtonLabel() {
      return this.isFilled && this.getCurrentAnswer.submit
        ? this.dictionary.submitLabel
        : this.dictionary.nextLabel;
    },

    getCurrentQuestion() {
      return this.questions[this.history[this.history.length - 1]];
    },

    getCurrentAnswer() {
      return this.isFilled ? this.answers[this.formData[this.getCurrentQuestion.id]] : null;
    },

    isFilled() {
      return !!this.formData[this.getCurrentQuestion.id];
    },

    shouldAbort() {
      return !this.getCurrentAnswer.nextQuestion && !this.getCurrentAnswer.submit;
    },
  },

  beforeMount() {
    this.setQuestionId(Object.keys(this.questions)[0]);
  },

  mounted() {
    this.focusOnBackButton();
  },

  methods: {
    pushAnswerAnalytics(action) {
      const answer = this.formData[this.getCurrentQuestion.id];

      /* istanbul ignore next */
      if (answer) {
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'userActionEvent',
          category: 'diagnostic::haircolor',
          action: action || 'select::next',
          label: `${this.getCurrentQuestion.title}::${this.answers[answer].label}`,
          event_name: 'site_load_service',
          service_name: 'haircolordiag',
          service_category: 'diagnose',
          cta_name: `${this.answers[answer].label}`,
        });
      }
    },

    back() {
      let revertedQuestionId;

      if (this.history.length === 1) {
        window.location.assign(this.introUrl);
        return;
      }

      this.scrollReset();
      revertedQuestionId = this.history.pop();
      delete this.formData[revertedQuestionId];
    },

    next() {
      if (this.getCurrentAnswer.submit) {
        this.confirm();
      } else {
        this.setQuestionId(this.getCurrentAnswer.nextQuestion);
        this.focusOnBackButton();
      }
    },

    scrollReset() {
      window.scrollTo(0, 0);
    },

    setQuestionId(id) {
      if (this.getCurrentQuestion) {
        this.pushAnswerAnalytics();
      }
      this.scrollReset();
      this.history.push(id);
    },

    confirm() {
      this.pushAnswerAnalytics('select::submit');

      const alertId = 'hairColorDiag';

      /* istanbul ignore next */
      eventBus.emit('alert:toggle', alertId);

      /* istanbul ignore next */
      eventBus.on('alert:confirmed', (id) => {
        /* istanbul ignore next */
        if (id === alertId) {
          let url = new URL(window.location.href);
          let urlSearchParams = new URLSearchParams(window.location.search);
          let params = Object.fromEntries(urlSearchParams.entries());
          let paramsValue = params.utm_medium == 'qrcode' && params.utm_source == 'pos';
          let destinationURL = this.getCurrentAnswer.destinationURL;
          if (paramsValue) {
            const getQueryString = url.search;
            destinationURL = this.getCurrentAnswer.destinationURL + getQueryString;
          }
          this.submit(destinationURL, this.formData);
        }
      });
    },

    /* istanbul ignore next */
    submit(destinationURL, formData) {
      const url = new URL(destinationURL, document.location.origin);

      for (let [questionId, answerId] of Object.entries(formData)) {
        /* istanbul ignore else */
        if (this.questions[questionId].isIncludedInUrl) {
          url.searchParams.append(this.questions[questionId].name, this.answers[answerId].value);
        }
      }

      window.location.assign(url.href);
    },

    /* istanbul ignore next */
    focusOnBackButton() {
      this.$nextTick(() => this.$refs.backButton.focus());
    },
  },
};
</script>
