<template>
  <div class="haircareDiagTip">
    <p class="haircareDiagTip__title">{{ tip.title }}</p>
    <p class="haircareDiagTip__text">{{ tip.text }}</p>
  </div>
</template>

<script>
export default {
  name: 'HaircareDiagTip',
  props: {
    tip: { type: Object, required: true },
  },
};
</script>
