import haircolorDiag from './code/Scripts/Components/haircolorDiag.vue';
import haircolorDiagResult from './code/Scripts/Components/haircolorDiagResult.vue';
import haircolorDiagProductTile from './code/Scripts/Components/haircolorDiagProductTile.vue';

export default {
  components: {
    haircolorDiag,
    haircolorDiagResult,
    haircolorDiagProductTile,
  },
};
