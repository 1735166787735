<template>
  <fieldset :class="['generic-select generic-datepicker generic-form__component', computedClasses]">
    <legend v-show="isLabelVisible" :id="`dropdown-group-${id}`" class="generic-select__legend">
      {{ label }}
      <span v-if="required" class="required" aria-hidden="true">*</span>
    </legend>
    <div
      class="generic-select-dropdowns"
      :style="`grid-template-columns: repeat(${3 - hiddenDateFields.length}, 1fr);`"
    >
      <GenericDropdownContainer
        v-show="!isDateHidden('Month')"
        :id="selectMonthId"
        ref="dropdownMonth"
        custom-class="generic-select-dropdown"
        :input-name="name"
        :type="type"
        :selected-options="[selectedDate.month]"
        :custom-selected-label="getMonthLabel('selected')"
        :input-label="dateFieldLabels.month"
        :required="required"
        :disabled="disabled || readonly"
        :readonly="readonly"
        :invalid="isInvalid"
        :has-description="hasDescription"
        :description-id="ariaDescriptionIds"
        :has-errors="isInvalid"
        :placeholder="placeholder"
        :default-value="getMonthLabel('default')"
        @handle-validation="handleValidation"
        @handle-input="handleInput($event, 'month')"
      >
        <div
          v-if="defaultDateLabels && !defaultDateLabels.month"
          :value="null"
          class="generic-select__option-item"
          disabled="disabled"
          :selected="!defaultDateLabels.month"
          tabindex="-1"
        >
          {{ dateFieldPlaceholders.month }}
        </div>
        <div
          v-for="(option, key) in date.months"
          :id="`option-month-id-${option.value ? option.value : 'month-blank'}`"
          :key="key"
          role="option"
          :value="option.value"
          class="generic-select__option-item"
          :class="{ '-selected': option.value === selectedDate.month }"
          :aria-selected="option.value === selectedDate.month"
        >
          {{ option.label }}
        </div>
      </GenericDropdownContainer>

      <GenericDropdownContainer
        v-show="!isDateHidden('Day')"
        :id="selectDayId"
        ref="dropdownDay"
        custom-class="generic-select-dropdown"
        :input-name="name"
        :type="type"
        :selected-options="[selectedDate.day]"
        :input-label="dateFieldLabels.day"
        :required="required"
        :disabled="disabled || readonly"
        :readonly="readonly"
        :invalid="isInvalid"
        :has-description="hasDescription"
        :description-id="ariaDescriptionIds"
        :has-errors="isInvalid"
        :placeholder="placeholder"
        :default-value="defaultValue.day"
        @handle-validation="handleValidation"
        @handle-input="handleInput($event, 'day')"
      >
        <div
          v-if="defaultDateLabels && !defaultDateLabels.day"
          :value="null"
          class="generic-select__option-item"
          disabled="disabled"
          :selected="!defaultDateLabels.day"
          tabindex="-1"
        >
          {{ dateFieldPlaceholders.day }}
        </div>
        <div
          v-for="(option, key) in date.days"
          :id="`option-day-id-${option.value ? option.value : 'day-blank'}`"
          :key="key"
          role="option"
          :value="option.value"
          class="generic-select__option-item"
          :class="{ '-selected': option.value === selectedDate.day }"
          :aria-selected="option.value === selectedDate.day"
        >
          {{ option.label }}
        </div>
      </GenericDropdownContainer>

      <GenericDropdownContainer
        v-show="!isDateHidden('Year')"
        :id="selectYearId"
        ref="dropdownYear"
        custom-class="generic-select-dropdown"
        :input-name="name"
        :type="type"
        :selected-options="[selectedDate.year]"
        :input-label="dateFieldLabels.year"
        :required="required"
        :disabled="disabled || readonly"
        :readonly="readonly"
        :invalid="isInvalid"
        :has-description="hasDescription"
        :description-id="ariaDescriptionIds"
        :has-errors="isInvalid"
        :placeholder="placeholder"
        :default-value="defaultValue.year"
        @handle-validation="handleValidation"
        @handle-input="handleInput($event, 'year')"
      >
        <div
          v-if="defaultDateLabels && !defaultDateLabels.year"
          :value="null"
          class="generic-select__option-item"
          disabled="disabled"
          :selected="!defaultDateLabels.year"
          tabindex="-1"
        >
          {{ dateFieldPlaceholders.year }}
        </div>
        <div
          v-for="(option, key) in date.years"
          :id="`option-year-id-${option.value ? option.value : 'year-blank'}`"
          :key="key"
          role="option"
          :value="option.value"
          class="generic-select__option-item"
          :class="{ '-selected': option.value === selectedDate.year }"
          :aria-selected="option.value === selectedDate.year"
        >
          {{ option.label }}
        </div>
      </GenericDropdownContainer>
    </div>

    <div v-show="hasDescription" :id="`description-${id}`">
      <div class="generic-errors">
        <div v-for="(error, key) in errorMessages" :key="key" class="generic-errors__item">
          {{ error }}
        </div>
      </div>
      <slot name="helperText"></slot>
    </div>
  </fieldset>
</template>

<script>
import { ref } from 'vue';
import GenericDropdownContainer from './generic-dropdown-container.vue';

export default {
  name: 'GenericDatepicker',
  components: {
    GenericDropdownContainer,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      default: null,
      type: [Object, String],
    },
    label: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: [Array, String],
      default: null,
    },
    options: {
      type: [Array, String],
      default: null,
    },
    startYear: {
      default: 1940,
      type: Number,
    },
    dateFieldAutocomplete: {
      default: () => ({
        day: '',
        month: '',
        year: '',
      }),
      type: Object,
    },
    dateFieldLabels: {
      default: () => ({
        day: 'Day',
        month: 'Month',
        year: 'Year',
      }),
      type: Object,
    },
    dateFieldPlaceholders: {
      default: () => ({
        day: 'DD',
        month: 'MM',
        year: 'YYYY',
      }),
      type: Object,
    },
    hiddenDateFields: {
      type: [Array],
      default: null,
    },
    helpText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    pristine: {
      type: Boolean,
      default: true,
    },
    defaultValue: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:modelValue', 'reset-errors', 'input'],
  setup() {
    const dropdownDay = ref();
    const dropdownMonth = ref();
    const dropdownYear = ref();

    const triggerHideList = (event, type) => {
      switch (type) {
        case 'day':
          dropdownDay.value.collapseFromListbox(event);
          break;
        case 'month':
          dropdownMonth.value.collapseFromListbox(event);
          break;
        case 'year':
          dropdownYear.value.collapseFromListbox(event);
          break;
        default:
          break;
      }
    };

    return { triggerHideList, dropdownDay, dropdownMonth, dropdownYear };
  },
  data() {
    return {
      selectedDate: this.generateSelectDate(),
      date: {
        days: this.generateDays(),
        months: this.generateMonths(),
        years: this.generateYears(),
      },
      isInvalid: false,
      focus: false,
      hadFocus: false,
      defaultDateLabels: null,
      isMounted: false,
    };
  },
  computed: {
    computedClasses() {
      return {
        'generic--active': this.focus,
        'generic--disabled': this.disabled,
        'generic--readonly': this.readonly,
        'generic--has-errors': this.isInvalid,
      };
    },
    isLabelVisible() {
      return this.label != null && this.label !== '';
    },
    ariaDescriptionIds() {
      return this.hasDescription ? `description-${this.id}` : null;
    },
    hasDescription() {
      return this.errorMessages || this.$slots.helperText;
    },
    selectDayId() {
      return `day-${this.id}`;
    },
    selectMonthId() {
      return `month-${this.id}`;
    },
    selectYearId() {
      return `year-${this.id}`;
    },
  },
  watch: {
    selectedDate: {
      handler(newValue) {
        this.copyValue(newValue);
      },
      deep: true,
    },
    errorMessages: {
      handler() {
        this.handleValidation();
      },
      deep: true,
    },
  },
  beforeMount() {
    // Here we are following the Vue2 convention on custom v-model:
    // https://github.com/vuejs/vue/issues/2873#issuecomment-223759341
    this.copyValue(this.value);
  },
  mounted() {
    this.defaultDateLabels = this.value;
    this.setDefaultValues();
    this.isMounted = true;
  },
  methods: {
    handleFocus(focused) {
      this.focus = focused;
      this.hadFocus = true;

      if (!focused) {
        this.handleValidation();
      }
    },
    setDefaultValues() {
      let formattedDate = null;
      if (this.selectedDate) {
        formattedDate = this.formatDate();
        formattedDate =
          formattedDate?.includes('null') || formattedDate?.includes('undefined')
            ? null
            : formattedDate;
        this.$emit('update:modelValue', formattedDate);
        if (formattedDate) {
          this.defaultDateLabels = null;
        }
      }
    },
    hasYearMonthDay() {
      return this.selectedDate.year && this.selectedDate.month && this.selectedDate.day;
    },
    getMonthLabel(type) {
      let monthLabel;
      let refData = type === 'selected' ? this.selectedDate.month : this.defaultValue.month;
      this.date.months.forEach((month) => {
        if (month.value === refData) monthLabel = month.label;
      });
      return monthLabel;
    },
    formatDate() {
      let formattedValue;
      let unselectedInputs = this.inputsUnselected();
      let hasNullValue = Object.values(this.selectedDate).some((date) => {
        const hasDefaultValue = this.defaultValue[date] !== null;
        return !!((date === null || date === '' || date === undefined) && !hasDefaultValue);
      });
      if (unselectedInputs) {
        formattedValue = '';
      } else if (hasNullValue) {
        formattedValue = null;
      } else {
        let finalDate = {};
        Object.keys(this.selectedDate).some((date) => {
          const dateHidden = this.isDateHidden(date.charAt(0).toUpperCase() + date.slice(1));
          if (dateHidden && this.selectedDate[date] === null) {
            finalDate[date] = this.defaultValue[date];
          } else {
            finalDate[date] = this.selectedDate[date];
          }
        });
        formattedValue = `${this.roundNumber(finalDate.month)}/${this.roundNumber(finalDate.day)}/${
          finalDate.year === '' ? null : finalDate.year
        }`;
      }
      return formattedValue;
    },
    handleOnChange() {
      this.setDefaultValues();
      this.handleValidation();
      if (this.isMounted) this.$emit('input');
    },
    handleInput(event, type) {
      const isKeyboardEvent = event instanceof KeyboardEvent;
      if (isKeyboardEvent) {
        const listBox = event.target.parentElement.querySelector('.generic-select__listbox');
        const listItem = listBox.querySelector('.-focused');
        this.selectedDate[type] = listItem.getAttribute('value');
      } else {
        this.selectedDate[type] = event.target.getAttribute('value');
      }
      this.handleOnChange();
      this.triggerHideList(event, type);
    },
    handleValidation() {
      this.isInvalid = this.isInvalidState();
    },
    isInvalidState() {
      if (this.inputsUnselected()) {
        this.$emit('reset-errors');
        return false;
      } else {
        return this.errorMessages !== null && this.errorMessages.length > 0;
      }
    },
    inputsUnselected() {
      return (
        !this.required &&
        this.pristine === false &&
        Object.values(this.selectedDate).every((date) => date === '' || date === null)
      );
    },
    copyValue(value) {
      if (!value) {
        this.selectedDate = this.generateSelectDate();
      } else {
        this.selectedDate = value;
        this.handleOnChange();
      }
    },
    roundNumber(number) {
      return !number ? null : `0${number}`.slice(-2);
    },
    generateDays() {
      let defaultDay = this.value ? this.value.day : null;
      const dObject = [];

      for (var d = 0; d < 32; d++) {
        let dateValue = this.roundNumber(d);
        dObject.push({
          selected: defaultDay && defaultDay === d,
          value: dateValue ? dateValue : '',
          label: d === 0 ? ' ' : dateValue,
        });
      }
      return dObject;
    },
    generateMonths() {
      let defaultMonth = this.value ? this.value.month : null;
      const dObject = [];

      for (var m = -1; m < 12; m++) {
        const date = new Date(2009, m, 10);
        const month = date.toLocaleString(navigator.language, {
          month: 'long',
        });
        let calculatedMonth = m + 1;
        let monthValue = this.roundNumber(calculatedMonth);
        dObject.push({
          selected: defaultMonth && defaultMonth === calculatedMonth,
          value: monthValue ? monthValue : '',
          label: m === -1 ? ' ' : month,
        });
      }

      return dObject;
    },
    generateYears() {
      const d = new Date();
      const currentYear = d.getUTCFullYear();
      const dObject = [];

      let defaultYear = this.value ? parseInt(this.value.year) : null;

      for (var y = currentYear + 1; y >= this.startYear; y--) {
        dObject.push({
          selected: defaultYear && defaultYear === y,
          value: y === currentYear + 1 ? '' : y,
          label: y === currentYear + 1 ? ' ' : y,
        });
      }

      return dObject;
    },
    generateSelectDate() {
      return this.defaultValue;
    },
    isDateHidden(type) {
      return this.hiddenDateFields?.includes(type);
    },
  },
};
</script>
