const RECAPTHCA_URL = 'https://www.google.com/recaptcha/api.js';

export const recaptcha = {
  name: 'recaptcha',
  data() {
    return {
      recaptchaError: false,
    };
  },
  methods: {
    loadRecaptchaOnInput: (form, siteKey) => {
      const loadScript = () => {
        const script = document.createElement('script');
        script.setAttribute('async', '');
        script.setAttribute('defer', '');
        script.id = 'recaptchaScript';
        script.src = RECAPTHCA_URL;
        script.src = `${RECAPTHCA_URL}?render=${siteKey}`;
        document.getElementsByTagName('head')[0].appendChild(script);
        form.removeEventListener('input', loadScript);
      };
      form.addEventListener('input', loadScript);
    },
    generateToken: (siteKey) => {
      return new Promise((resolve) => {
        if (!window.grecaptcha) {
          resolve('');
        }
        window.grecaptcha.ready(function () {
          window.grecaptcha.execute(siteKey, { action: 'submit' }).then(function (token) {
            resolve(token);
          });
        });
      });
    },
  },
};
