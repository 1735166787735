<template>
  <div class="haircolorTreatForm__container">
    <div class="haircolorTreatForm__nav">
      <button class="haircolorTreatForm__backButton" @click="back()">
        {{ dictionary.backLabel }}
      </button>
    </div>

    <div class="haircolorTreatForm__grid">
      <div class="haircolorTreatForm__col haircolorTreatForm_container">
        <transition name="haircolorTreatSwipe" mode="out-in">
          <ul :key="`card-${getCurrentQuestion.name}`" class="haircolorTreatForm__answers">
            <li
              v-for="answer of getCurrentQuestion.answers"
              :key="answer.id"
              class="haircolorTreatRadio__container"
              :class="{ '-noTip': !answers[answer].tip }"
            >
              <input
                :id="answers[answer].id"
                v-model="formData[getCurrentQuestion.id]"
                class="haircolorTreatRadio__input haircolorTreatForm__input"
                type="radio"
                :value="answers[answer].id"
              />
              <div class="haircolorTreatRadio__control" :data-id="answers[answer].id">
                <img
                  class="haircolorTreatRadio__thumbnail lazyload"
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                  :data-src="answers[answer].image.fileName"
                  :alt="answers[answer].image.alt"
                />
                <label class="haircolorTreatRadio__label" :for="answers[answer].id">
                  <span>{{ answers[answer].label }}</span>
                </label>
              </div>
              <HaircolorTreatTip v-if="answers[answer].tip" :tip="answers[answer].tip" />

              <HaircolorTreatPopup
                v-if="answers[answer].popup"
                :popup="answers[answer].popup"
                :answerid="answers[answer].id"
              />
            </li>
          </ul>
        </transition>
      </div>

      <div class="haircolorTreatForm__col">
        <div class="haircolorTreatForm__hero">
          <transition name="haircolorTreatHero" mode="out-in">
            <div :key="`heroText-${getCurrentQuestion.name}`">
              <h1 class="haircolorTreatForm__title">{{ getCurrentQuestion.title }}</h1>
              <p class="haircolorTreatForm__subtitle">
                {{ getCurrentQuestion.subtitle || dictionary.subtitle }}
              </p>
              <button
                class="haircolorTreatForm__nextButton -isDesktop"
                type="button"
                :disabled="!isFilled || shouldAbort"
                @click="next()"
              >
                {{ getNextButtonLabel }}
              </button>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <button
      class="haircolorTreatForm__nextButton -isMobile"
      :disabled="!isFilled || shouldAbort"
      @click="next()"
    >
      {{ getNextButtonLabel }}
    </button>
  </div>
</template>

<script>
import HaircolorTreatTip from './HaircolorTreatTip.vue';
import HaircolorTreatPopup from './HaircolorTreatPopup.vue';
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

export default {
  name: 'HaircolorTreat',

  components: {
    HaircolorTreatTip,
    HaircolorTreatPopup,
  },

  props: {
    introUrl: { type: String, required: true },
    dictionary: { type: Object, required: true },
    questions: { type: Object, required: true },
    answers: { type: Object, required: true },
  },

  data() {
    return {
      previousQuestionId: null,
      currentQuestionId: null,
      formData: {},
      history: [],
      answerID: '',
    };
  },

  computed: {
    getNextButtonLabel() {
      return this.isFilled && this.getCurrentAnswer.submit
        ? this.dictionary.submitLabel
        : this.dictionary.nextLabel;
    },

    getCurrentQuestion() {
      return this.questions[this.history[this.history.length - 1]];
    },

    getCurrentAnswer() {
      return this.isFilled ? this.answers[this.formData[this.getCurrentQuestion.id]] : null;
    },

    isFilled() {
      return !!this.formData[this.getCurrentQuestion.id];
    },

    shouldAbort() {
      return !this.getCurrentAnswer.nextQuestion && !this.getCurrentAnswer.submit;
    },
  },

  beforeMount() {
    this.setQuestionId(Object.keys(this.questions)[0]);
  },

  methods: {
    pushAnswerAnalytics(category, action) {
      const answer = this.formData[this.getCurrentQuestion.id];
      if (answer) {
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'userActionEvent',
          category: category || 'diagnostic::haircolor',
          action: action || 'select::next',
          label: `${this.getCurrentQuestion.title}::${this.answers[answer].label}`,
        });
      }
    },

    back() {
      let revertedQuestionId;

      if (this.history.length === 1) {
        window.location.assign(this.introUrl);
        return;
      }

      this.scrollReset();
      revertedQuestionId = this.history.pop();
      delete this.formData[revertedQuestionId];
    },

    next() {
      if (this.getCurrentAnswer.submit) this.confirm();
      else this.setQuestionId(this.getCurrentAnswer.nextQuestion);
    },

    scrollReset() {
      window.scrollTo(0, 0);
    },

    setQuestionId(id) {
      if (this.getCurrentQuestion) {
        this.pushAnswerAnalytics();
      }
      this.scrollReset();
      this.history.push(id);
    },

    confirm() {
      this.pushAnswerAnalytics('diagnostic::haircolor::final question', 'select::submit');

      const alertId = 'hairColorDiag';
      /* istanbul ignore next */
      eventBus.emit('alert:toggle', alertId);

      /* istanbul ignore next */
      eventBus.on('alert:confirmed', (id) => {
        /* istanbul ignore next */
        if (id === alertId) this.submit(this.getCurrentAnswer.destinationURL, this.formData);
      });
    },

    /* istanbul ignore next */
    submit(destinationURL, formData) {
      const url = new URL(destinationURL, document.location.origin);

      for (let [questionId, answerId] of Object.entries(formData)) {
        /* istanbul ignore else */
        if (this.questions[questionId].isIncludedInUrl) {
          url.searchParams.append(this.questions[questionId].name, this.answers[answerId].value);
        }
      }

      window.location.assign(url.href);
    },
  },
};
</script>
