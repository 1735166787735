<template>
  <article class="oap-card oap-card--type-amp" :class="{ 'oap-card--theme-mex': isMex }">
    <figure>
      <component
        :is="componentType.is"
        ref="ampLink"
        v-bind="componentType"
        class="oap-card__amp-link"
      >
        <OapStoriesCircle :image-alt="imageAlt" :image-src="imageSrc" />
      </component>
      <figcaption v-if="caption">{{ caption }}</figcaption>
    </figure>
  </article>
</template>

<script>
import OapStoriesCircle from './oap-stories-circle';

import eventBus from '@loreal/eventbus-js';
import { SELECT_STORY, VIEWED_STORY, VIEWED_INDEXES_STORAGE_KEY } from '../constants/eventNames';
import { readFromLocalStorage } from '../storage/storage';

import { tag } from '../../../../../Foundation/Core/code/Scripts/analyticsHandler/directives/tag';

export default {
  name: 'OapStoriesCircleLink',
  components: {
    OapStoriesCircle,
  },
  directives: {
    tag,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
    href: {
      type: String,
      default: '',
    },
    tagging: {
      type: Object,
      default: () => {},
    },
    caption: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    imageAlt: {
      type: String,
      default: '',
    },
    isMex: {
      type: Boolean,
      default: false,
    },
    storyIndex: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      viewedIndexes: null,
    };
  },
  computed: {
    isLinkVisited() {
      return this.viewedIndexes?.includes(Number(this.storyIndex));
    },
    componentType() {
      if (this.href) {
        return {
          is: 'a',
          href: this.href,
        };
      }
      return {
        is: 'span',
        class: this.isLinkVisited ? 'oap-card__amp-link--is-visited' : '',
        id: this.id,
      };
    },
  },
  created() {
    this.viewedIndexes = readFromLocalStorage(VIEWED_INDEXES_STORAGE_KEY);
    eventBus.on(VIEWED_STORY, () => {
      this.viewedIndexes = readFromLocalStorage(VIEWED_INDEXES_STORAGE_KEY);
    });
  },
  mounted() {
    this.handleTagging();
  },
  methods: {
    handleTagging() {
      // look for parent to click on => whole card should be clickable
      const parentElement = this.$el.closest('figure');
      if (parentElement) {
        parentElement.addEventListener('click', this.onClick);
        parentElement.style.cursor = 'pointer';
      } else {
        this.$refs.ampLink.addEventListener('click', this.onClick);
      }

      tag.mounted(parentElement || this.$refs.ampLink, {
        arg: 'useractionevent',
        value: this.tagging,
      });
    },
    onClick() {
      // open IG stories only when componentType is not a link
      if (!this.componentType.href) {
        eventBus.emit(SELECT_STORY, this.id);
      } else {
        window.location.href = this.componentType.href;
      }
    },
  },
};
</script>
