<template>
  <OapFocusTrap :is-visible="showModal">
    <transition name="modal">
      <div v-if="showModal" class="oap-popin__wrapper -geolocation" @keyup.esc="closePopIn">
        <div class="oap-popin__mask" @click="closePopIn"></div>

        <div class="oap-popin__container">
          <header class="oap-popin__header">
            <button
              v-tag:useractionevent.dom="{ label: 'stay on website' }"
              class="oap-popin__close"
              @click="closePopIn"
            >
              <svg class="icon" role="img" aria-labelledby="close-geolocation-popin-icon">
                <title id="close-geolocation-popin-icon">Close</title>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
              </svg>
            </button>
          </header>

          <div class="oap-popin__content scrollbarCustom">
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </OapFocusTrap>
</template>

<script>
import OapFocusTrap from '../../../../OapFocusTrap/code/Scripts/components/OapFocusTrap.vue';

export default {
  name: 'OapGeolocationPopin',

  components: {
    OapFocusTrap,
  },

  props: {
    cookieName: { type: String, required: true },
    cookieValue: { type: Number, default: 1 },
    cookiePath: { type: String, default: '/' },
  },

  data() {
    return {
      showModal: true,
    };
  },

  mounted() {
    const closeButton = document.querySelector('[data-ref="oap-geolocation-popin__close-button"]');
    if (closeButton) {
      /* istanbul ignore next */
      closeButton.addEventListener('click', this.closePopIn);
    }
  },

  methods: {
    closePopIn() {
      document.cookie = `${this.cookieName}=${this.cookieValue}; path=${this.cookiePath}`;
      this.showModal = false;
    },
  },
};
</script>
