<template>
  <div class="oap-countdown">
    <ul class="oap-countdown__list">
      <li
        v-for="(time, key, index) in estimatedTime"
        :key="`time-key-${key}-${index}`"
        class="oap-countdown__item"
      >
        <span
          v-for="(digit, yIndex) in time"
          :key="`time-key-${key}-${yIndex}-${digit}`"
          class="oap-countdown__digit"
        >
          {{ digit }}
        </span>
        <span class="oap-countdown__translation"> {{ translations[key] }} </span>
      </li>
    </ul>
  </div>
</template>

<script>
import intervalToDuration from 'date-fns/intervalToDuration';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import Timer from '../timer.js';

export default {
  name: 'OapCountdown',
  props: {
    endDate: {
      type: [String, Date],
      default: () => new Date(new Date().getFullYear(), 11, 31),
    },
    translations: {
      type: Object,
      default: () => ({
        days: 'days',
        hours: 'hours',
        minutes: 'min',
        seconds: 'sec',
      }),
    },
  },
  data() {
    return {
      timer: null,
      currentDuration: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      differenceInDays: 0,
    };
  },
  computed: {
    clearDuration() {
      const { hours, minutes, seconds } = this.currentDuration;
      return {
        days: this.differenceInDays,
        hours,
        minutes,
        seconds,
      };
    },
    estimatedTime() {
      const { days, hours, minutes, seconds } = this.clearDuration;
      return {
        days: this.formatNumberToArrayOfDigits(days),
        hours: this.formatNumberToArrayOfDigits(hours),
        minutes: this.formatNumberToArrayOfDigits(minutes),
        seconds: this.formatNumberToArrayOfDigits(seconds),
      };
    },
    isDateBefore() {
      return isBefore(new Date(), this.parsedEndDate);
    },
    isTimerFinished() {
      return Object.values(this.clearDuration).every((x) => x === 0);
    },
    parsedEndDate() {
      return parseISO(this.endDate);
    },
  },
  created() {
    if (this.isDateBefore) {
      /**
       * the timer was initially created because,
       * web browsers, like all applications, take turns for a piece of CPU time,
       * and the time they have to wait will vary, depending on the load.
       * This is what causes the latency in timers — a 200ms timer may actually take 202ms or 204,
       * and this will gradually send the stopwatch out of time.
       * more info: in timer.js
       */
      this.timer = new Timer(() => {
        this.currentDuration = intervalToDuration({
          start: new Date(),
          end: this.parsedEndDate,
        });

        this.differenceInDays = differenceInCalendarDays(this.parsedEndDate, new Date());

        if (this.isTimerFinished) {
          this.destroyTimer();
        }
      });
    }
  },
  methods: {
    formatNumberToArrayOfDigits(value) {
      return String(value).padStart(2, '0').split('').map(Number);
    },
    destroyTimer() {
      if (this.timer) {
        this.timer.stop();
        this.timer = null;
      }
    },
  },
};
</script>
