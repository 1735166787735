<template>
  <div class="haircareDiagForm__container">
    <transition name="haircareDiagFadeIn">
      <div
        :key="`bg-${getCurrentQuestion.name}`"
        v-background="getCurrentQuestion.backgrounds"
        aria-hidden="true"
        class="haircareDiagForm__background"
      ></div>
    </transition>
    <div v-show="showOverlay" class="haircareDiagLoader__overlay">
      <div class="haircareDiagLoader__container">
        <OapLoader />
      </div>
      <div class="haircareDiagLoader__text">{{ loaderText }}</div>
    </div>
    <div class="haircareDiagForm__grid">
      <div class="haircareDiagForm__col">
        <div class="haircareDiagForm__hero">
          <button
            class="haircareDiagForm__backButton"
            type="button"
            :disabled="getCurrentStep <= 1"
            @click="back()"
          >
            {{ dictionary.backLabel }}
          </button>
          <transition :name="getHeroTransition" mode="out-in">
            <div :key="`heroText-${getCurrentQuestion.name}`">
              <span class="haircareDiagForm__onTitle">{{ getCurrentQuestion.onTitle }}</span>
              <h1 class="haircareDiagForm__title">{{ getCurrentQuestion.title }}</h1>
            </div>
          </transition>
        </div>
      </div>
      <div class="haircareDiagForm__col">
        <transition name="haircareDiagSwipe" mode="out-in">
          <div :key="`card-${getCurrentQuestion.name}`" class="haircareDiagForm__card">
            <div class="haircareDiagForm__stepIndicator">
              {{ getCurrentStep }} {{ dictionary.ofLabel }} {{ getTotalSteps }}
            </div>
            <div class="haircareDiagForm__label">{{ getCurrentQuestion.label }}</div>
            <div
              :class="[
                'haircareDiagForm__question',
                `--theme-${getCurrentQuestion.type === 'radio' ? 'tiles' : 'pills'}`,
              ]"
            >
              <div
                v-for="answer of getCurrentQuestion.answers"
                :key="answer"
                :class="['haircareDiagInput__container', !answers[answer].tip && '-noTip']"
              >
                <input
                  :id="answers[answer].id"
                  v-model="formData[getCurrentQuestion.name]"
                  class="haircareDiagInput__input"
                  :type="getCurrentQuestion.type === 'checkbox' ? 'checkbox' : 'radio'"
                  :value="answers[answer].value"
                />
                <div class="haircareDiagInput__control">
                  <img
                    v-if="answers[answer].image.fileName"
                    class="haircareDiagInput__thumbnail lazyload"
                    src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                    :data-src="answers[answer].image.fileName"
                    :alt="answers[answer].image.alt"
                  />
                  <!-- Tiles -->
                  <template v-if="getCurrentQuestion.type === 'radio'">
                    <label class="haircareDiagInput__label" :for="answers[answer].id">
                      <span class="haircareDiagInput__text">{{ answers[answer].label }}</span>
                    </label>
                  </template>
                  <!-- Pills -->
                  <template v-else>
                    <span class="haircareDiagInput__text">{{ answers[answer].label }}</span>
                    <label class="haircareDiagInput__label" :for="answers[answer].id"></label>
                  </template>
                </div>
                <haircare-diag-tip v-if="answers[answer].tip" :tip="answers[answer].tip" />
              </div>
            </div>
            <button
              v-if="getCurrentStep < getTotalSteps"
              class="haircareDiagForm__questionButton"
              type="button"
              :disabled="!isFilled"
              @click="next()"
            >
              {{ dictionary.nextLabel }}
            </button>
            <button
              v-else
              class="haircareDiagForm__questionButton"
              :disabled="!isFilled"
              @click="submit()"
            >
              {{ dictionary.submitLabel }}
            </button>
          </div>
        </transition>
      </div>
    </div>
    <div class="haircareDiagProgressBar" :style="{ '--progress': getProgress }"></div>
  </div>
</template>

<script>
import haircareDiagTip from './haircareDiagTip.vue';
import { background } from '../../../../../Foundation/Core/code/Scripts/directives/background/background';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';
import OapLoader from '../../../../OapLoader/code/Scripts/components/OapLoader.vue';

export default {
  name: 'HaircareDiag',

  components: {
    haircareDiagTip,
    OapLoader,
  },

  directives: {
    background,
  },

  props: {
    url: String,
    errorPage: String,
    dictionary: Object,
    questions: Array,
    answers: Object,
    loaderText: String,
    language: String,
    loaderTimeout: { type: Number, default: 100 },
  },

  data() {
    return {
      previousIndex: null,
      currentIndex: 0,
      formData: {},
      showOverlay: false,
    };
  },

  computed: {
    getProgress() {
      return Math.floor((this.getCurrentStep * 100) / this.getTotalSteps) + '%';
    },

    getCurrentStep() {
      return this.currentIndex + 1;
    },

    getTotalSteps() {
      return this.questions.length;
    },

    getHeroTransition() {
      return this.getPreviousQuestion &&
        this.getPreviousQuestion.onTitle === this.getCurrentQuestion.onTitle &&
        this.getPreviousQuestion.title === this.getCurrentQuestion.title
        ? null
        : 'haircareDiagHero';
    },

    getPreviousQuestion() {
      return this.previousIndex === null ? null : this.questions[this.previousIndex];
    },

    getCurrentQuestion() {
      return this.questions[this.currentIndex];
    },

    getAnalyticsLabel() {
      let answer = this.formData[this.getCurrentQuestion.name];
      if (answer) {
        answer =
          typeof answer === 'string'
            ? this.answers[answer].label.toLowerCase()
            : this.mapAnswerArray(this.answers, answer);
      }

      return `${this.getCurrentQuestion.label}::${answer}`;
    },

    isFilled() {
      return this.formData[this.getCurrentQuestion.name].length > 0;
    },
  },

  beforeMount() {
    const bgSet = new Set();
    const multiples = ['checkbox', 'select'];

    this.questions.map(({ name, type, backgrounds }) => {
      // populate unique bg filenames
      Object.values(backgrounds).forEach((filename) => bgSet.add(filename));
      // Prepare the form data structure
      this.formData[name] = multiples.includes(type) ? [] : '';
    });

    this.formData.errorPage = this.errorPage;

    this.formData.currentUrl = window.location.href;

    this.formData.lang = this.language;

    // preload images for background
    bgSet.forEach((url) => (new Image().src = url));
  },

  methods: {
    mapAnswerArray(answers, answerArray) {
      return answerArray
        .map((answer) => answers[answer].label)
        .join('|')
        .toLowerCase();
    },

    pushAnswerAnalytics(isFinal) {
      const action = isFinal ? 'select::see customized routine' : 'select::next question';

      AnalyticsHandler.getAnalyticsHandler().push({
        event_name: 'site_load_service',
        service_name: 'haircarediag',
        service_category: 'diagnose',
        cta_name: `${this.getAnalyticsLabel}`,
        type: 'userActionEvent',
        category: 'diagnostic::haircare',
        action,
        label: `${this.getAnalyticsLabel}`,
      });
    },

    back() {
      this.setIndex(Math.max(0, this.currentIndex - 1));
    },

    next() {
      this.pushAnswerAnalytics();
      this.setIndex(Math.min(this.questions.length - 1, this.currentIndex + 1));
    },

    scrollReset() {
      window.scrollTo({
        top: 0,
        left: 0,
        // added smooth behavior for scroll
        // it affects appearing/disappearing of the header
        behavior: 'smooth',
      });
    },

    setIndex(value) {
      this.scrollReset();
      this.previousIndex = this.currentIndex;
      this.currentIndex = value;
    },

    /* istanbul ignore next */
    submit() {
      const isFinal = true;
      /* istanbul ignore next */
      this.pushAnswerAnalytics(isFinal);

      // TODO loader
      /* istanbul ignore next */
      let form = document.createElement('FORM');
      /* istanbul ignore next */
      form.method = 'POST';
      /* istanbul ignore next */
      form.action = this.url;
      /* istanbul ignore next */
      for (let key in this.formData) {
        //TODO: CHECK THIS
        if (Object.prototype.hasOwnProperty.call(this.formData, key)) {
          let input = document.createElement('INPUT');
          input.type = 'hidden';
          input.name = key;
          input.value = this.formData[key];
          form.appendChild(input);
        }
      }
      /* istanbul ignore next */
      document.body.appendChild(form);
      this.showOverlay = true;

      setTimeout(() => {
        /* istanbul ignore next */
        form.submit();
      }, this.loaderTimeout);
    },
  },
};
</script>
