<template>
  <div v-if="tip" class="haircolorTreatTip">
    <p class="haircolorTreatTip__title">{{ tip.title }}</p>
    <div class="rich-text" v-html="tip.text"></div>
  </div>
</template>

<script>
export default {
  name: 'HaircolorTreatTip',
  props: {
    tip: { type: Object, required: true },
  },
};
</script>
