import HaircolorTreat from './code/Scripts/Components/HaircolorTreat.vue';
import HaircolorTreatResult from './code/Scripts/Components/HaircolorTreatResult.vue';
import HaircolorTreatProductTile from './code/Scripts/Components/HaircolorTreatProductTile.vue';

export default {
  components: {
    HaircolorTreat,
    HaircolorTreatResult,
    HaircolorTreatProductTile,
  },
};
