<template>
  <div class="generic-select__component">
    <GenericDropdownContainer
      :id="`select-${id}`"
      ref="dropdownContainer"
      :input-name="name"
      type="input"
      :selected-options="[selectedOption]"
      :custom-selected-label="getSelectedLabel"
      :input-label="label"
      :required="required"
      :disabled="disabled || readonly"
      :readonly="readonly"
      :invalid="invalid"
      :has-description="hasDescription"
      :description-id="ariaDescriptionIds"
      :has-errors="materialHasErrors"
      :placeholder="placeholder"
      :default-value="getDefaultValue"
      @handle-validation="handleValidation"
      @handle-input="handleOnChange"
    >
      <div
        :id="`generic-select__option-id--null-${id}`"
        class="generic-select__option-item"
        role="option"
        :value="null"
      >
        <div class="generic-select__option-text"></div>
      </div>
      <div
        v-for="(option, key) in options"
        :id="`generic-select__option-id--${key}`"
        :key="key"
        :value="key"
        class="generic-select__option-item"
        :class="{ '-selected': key === selectedOption }"
        role="option"
        :aria-selected="key === selectedOption"
      >
        <img
          v-if="extractOptionData(option, 'icon').length"
          class="generic-select__option-image"
          :src="extractOptionData(option, 'icon')"
          alt=""
        />
        <div class="generic-select__option-text">
          {{ extractOptionData(option, 'label') }}
        </div>
      </div>
    </GenericDropdownContainer>

    <div v-show="hasDescription" :id="`description-${id}`">
      <div class="generic-errors">
        <div v-for="(error, key) in computedErrors" :key="key" class="generic-errors__item">
          {{ error }}
        </div>
      </div>
      <slot name="helperText"></slot>
    </div>
  </div>
</template>

<script>
import GenericDropdownContainer from './generic-dropdown-container.vue';
import { ref } from 'vue';

export default {
  name: 'GenericSelect',
  components: {
    GenericDropdownContainer,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [Array, String],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'input'],
  setup() {
    const dropdownContainer = ref();

    const triggerHideList = (event) => {
      dropdownContainer.value.collapseFromListbox(event);
    };

    const setGridStyle = () => {
      dropdownContainer.value.setGridStyle();
    };

    return { triggerHideList, setGridStyle, dropdownContainer };
  },
  data() {
    return {
      selectedOption: this.value,
      invalid: false,
    };
  },
  computed: {
    ariaDescriptionIds() {
      return this.hasDescription ? `description-${this.id}` : null;
    },
    hasDescription() {
      return this.errorMessages || this.$slots.helperText;
    },
    materialHasErrors() {
      return this.invalid || this.hasErrorMessages;
    },
    hasErrorMessages() {
      return this.errorMessages !== null && this.errorMessages.length > 0;
    },
    computedErrors() {
      return typeof this.errorMessages === 'string' ? [this.errorMessages] : this.errorMessages;
    },
    activeOptionLabel() {
      const activeOption = document.querySelector(
        `div#generic-select__option-id--${this.selectedOption}`
      );
      return activeOption?.innerText;
    },
    activeOptionImage() {
      const activeOption = document.querySelector(
        `div#generic-select__option-id--${this.selectedOption}`
      );
      const activeImage = activeOption?.querySelector('img');
      return activeImage?.getAttribute('src');
    },
    getSelectedLabel() {
      return this.selectedOption
        ? this.activeOptionImage
          ? `<img
        class="generic-select__selected-image"
        src="${this.activeOptionImage}"
      /><span class="generic-select__selected-label">${this.activeOptionLabel}</span>`
          : `<span class="generic-select__selected-label">${this.activeOptionLabel}</span>`
        : null;
    },
    getDefaultValue() {
      return this.selectedOption
        ? this.extractOptionData(this.options[this.selectedOption], 'label')
        : this.extractOptionData(this.options[this.defaultValue], 'label');
    },
  },
  mounted() {
    this.handleDefaultValue();
  },
  methods: {
    handleOnChange(event, isKeyboardEvent) {
      if (isKeyboardEvent) {
        const listbox = event.target.parentElement.querySelector('.generic-select__listbox');
        const focusedOption = listbox.querySelector('.-focused');
        this.selectedOption = focusedOption.getAttribute('value');
      } else {
        this.selectedOption = event.target.getAttribute('value');
      }
      this.$emit('update:modelValue', this.selectedOption);
      this.$emit('input');
      this.handleValidation();
      this.triggerHideList(event);
      this.setGridStyle();
    },
    handleValidation() {
      this.invalid = this.required && !this.selectedOption;
    },
    handleDefaultValue() {
      if (!this.selectedOption && this.defaultValue) this.selectedOption = this.defaultValue;
    },
    extractOptionData(option, key) {
      if (typeof option === 'object') return option[key];
      if (this.isValidJson(option)) {
        const optionItem = JSON.parse(option);
        if (optionItem[key].length) return optionItem[key];
        return '';
      } else {
        if (key === 'label') return option;
        return '';
      }
    },
    isValidJson(string) {
      if (typeof string !== 'string') {
        return false;
      }
      try {
        JSON.parse(string);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>
