<template>
  <section
    v-show="isEnabledProxy"
    class="oap-stories__wrapper"
    :style="`background-image: url('${activeUserStory.background}')`"
  >
    <div v-if="activeUserStory.background" class="oap-stories__blur"></div>
    <article class="oap-stories">
      <button class="oap-stories__close" @click="onClose">
        <span class="is-sr-only">{{ translations.close }}</span>
        <svg aria-hidden="true" class="icon">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
        </svg>
      </button>
      <ul
        v-for="(story, index) in stories"
        :id="story.id"
        :key="index"
        class="oap-stories__story"
        :style="setStoryStyle(index)"
        @click="index !== selectedIndex ? selectStory(story.id) : ''"
      >
        <li class="oap-stories__story-source">
          <div v-if="index !== selectedIndex" class="oap-stories__story-cover">
            <div
              class="oap-stories__story-background"
              :style="`background-image: url('${story.background}')`"
            ></div>

            <OapStoriesCircle
              v-if="Object.keys(story.user).length"
              class="oap-stories__circle"
              :is-mex="story.isMex"
              :image-alt="`Avatar for ${story.user.username}`"
              :image-src="story.user.avatar"
              :username="story.user.username"
              :render-outer-circle="false"
            />
          </div>

          <OapStoriesItem
            :current-story="getCurrentStory(story, index)"
            @play-story="playStory"
            @pause-story="pauseStory"
            @touch-start="onTouchStart($event)"
            @touch-end="onTouchEnd($event)"
          >
            <template #shop-now>
              <a
                v-if="getCurrentStory(story, index).productUrl && index === selectedIndex"
                v-tag:useractionevent="getCurrentStory(story, index).tag"
                class="oap-stories__shop-now-button"
                :href="getCurrentStory(story, index).productUrl"
                target="_blank"
                @click="pauseStory"
              >
                {{ getCurrentStory(story, index).ctaText }}
              </a>
            </template>
          </OapStoriesItem>
          <header v-if="index === selectedIndex" class="oap-stories__story-header">
            <OapStoriesTime
              :percent="percent"
              :current-story-key="key"
              :data-length="story.data.length"
            />

            <div class="oap-stories__story-user-control">
              <OapStoriesCircle
                v-if="Object.keys(story.user).length"
                class="oap-stories__circle"
                :is-mex="story.isMex"
                :image-alt="`Avatar for ${story.user.username}`"
                :image-src="story.user.avatar"
                :username="story.user.username"
                :render-outer-circle="false"
              />

              <OapStoriesStoryControls
                :is-muted="isMuted"
                :is-paused="isPaused"
                :is-video-story="isActiveStoryVideo"
                :translations="translations.storyControls"
                @toggle-volume="toggleVideoVolume"
                @story-trigger="onStoryTrigger"
              />
            </div>
          </header>
        </li>
        <OapStoriesSlideControls
          v-if="index === selectedIndex"
          :translations="translations.slideControls"
          @next-story="nextStory(index)"
          @previous-story="previousStory"
          @touch-start="onTouchStart($event)"
          @touch-end="onTouchEnd($event)"
        />
      </ul>
    </article>
  </section>
</template>

<script>
import OapStoriesItem from './oap-stories-item';
import OapStoriesSlideControls from './oap-stories-slide-controls';
import OapStoriesStoryControls from './oap-stories-story-controls';
import OapStoriesTime from './oap-stories-time';
import OapStoriesCircle from './oap-stories-circle';

import eventBus from '@loreal/eventbus-js';
import { SELECT_STORY, VIEWED_STORY, VIEWED_INDEXES_STORAGE_KEY } from '../constants/eventNames';
import { readFromLocalStorage, writeToLocalStorage } from '../storage/storage';

import { tag } from '../../../../../Foundation/Core/code/Scripts/analyticsHandler/directives/tag';

export default {
  name: 'OapStories',
  components: {
    OapStoriesItem,
    OapStoriesSlideControls,
    OapStoriesStoryControls,
    OapStoriesTime,
    OapStoriesCircle,
  },
  directives: {
    tag,
  },
  props: {
    stories: {
      type: Array,
      required: true,
    },
    duration: {
      type: Number,
      default: 15000,
      validator(value) {
        return value <= 15000;
      },
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
    isEnabled: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object,
      default: () => ({
        close: 'Close',
        slideControls: {
          next: 'Next story',
          previous: 'Previous story',
        },
        storyControls: {
          play: 'Play story',
          pause: 'Pause story',
          cannotBeMuted: 'This story cannot be muted',
          unmute: 'Unmute video',
          mute: 'Mute video',
        },
      }),
    },
  },
  data: () => ({
    selectedIndex: 0,
    selectedIndexes: [],
    difference: 0,
    key: 0,
    percent: 0,
    timer: 0,
    progress: 0,
    interval: 0,
    isPaused: false,
    isMuted: true,
    newDuration: 0,
    pausedPercent: 0,
    touchStartX: 0,
    touchEndX: 0,
    isEnabledProxy: false,
  }),
  computed: {
    hasEveryStoryEnded() {
      return this.selectedIndex >= this.stories.length - 1 && this.hasEveryUserStoryEnded;
    },
    hasEveryUserStoryEnded() {
      return this.key >= this.stories[this.selectedIndex].data.length - 1;
    },
    activeUserStory() {
      return this.stories[this.selectedIndex];
    },
    activeStory() {
      return this.activeUserStory.data[this.key];
    },
    isActiveStoryVideo() {
      return this.activeStory?.type === 'video';
    },
  },
  watch: {
    isEnabled: {
      handler(value) {
        this.isEnabledProxy = value;
      },
      immediate: true,
    },
    selectedIndex(value) {
      this.readAndWriteToLocalStorage(value);
      eventBus.emit(VIEWED_STORY, this.selectedIndexes);
    },
  },
  created() {
    eventBus.on(SELECT_STORY, (id) => {
      const indexById = this.findStoryIndexById(id);
      this.readAndWriteToLocalStorage(indexById);

      eventBus.emit(VIEWED_STORY);

      this.isEnabledProxy = true;
      this.selectStory(id);
    });
  },
  mounted() {
    if (this.isEnabledProxy) {
      this.play();
      this.selectStory(this.currentIndex);
    }
  },
  methods: {
    getStoryData(story, index) {
      const isViewedIndex = this.getLastViewedIndex(story);
      return index === this.selectedIndex ? story.data[this.key] : story.data[isViewedIndex];
    },
    getCurrentStory(story, index) {
      const { mediaUrl, muted, type, isViewed, productUrl, ctaText, tag } = this.getStoryData(
        story,
        index
      );

      return {
        mediaUrl,
        muted,
        type,
        isViewed,
        productUrl,
        ctaText,
        tag,
      };
    },
    getNotViewedIndex(story) {
      return story.data.findIndex((item) => !item.isViewed);
    },
    getLastViewedIndex(story) {
      const keyIndex = this.getNotViewedIndex(story);
      const index = story.data.length - 1;
      return keyIndex === -1 ? index : keyIndex;
    },
    getActiveMedia() {
      if (this.isEnabledProxy) {
        return this.$el
          .querySelectorAll('.oap-stories__story-source')
          [this.selectedIndex].querySelector('.oap-stories__media');
      }
    },
    setStoryStyle(index) {
      return index === this.selectedIndex
        ? `transform: translate(0px)`
        : `transform: translate(${this.calculateTransform(index)}px) scale(0.5);cursor:pointer;`;
    },
    onClose() {
      this.isEnabledProxy = false;
      this.reset();
    },
    onStoryTrigger() {
      this.isPaused ? this.playStory() : this.pauseStory();
    },
    onEveryStoryEnd() {
      this.difference = 0;
      this.selectedIndex = 0;
      this.key = 0;
    },
    onEveryUserStoryEnd(index) {
      this.difference--;
      this.stories[index].data[this.key].isViewed = true;
      this.selectedIndex++;
      this.key = this.getLastViewedIndex(this.stories[this.selectedIndex]);
    },
    onCurrentStoryEnd(index) {
      this.stories[index].data[this.key].isViewed = true;
      this.key++;
    },
    findStoryIndexById(id) {
      return Array.from(this.$el.querySelectorAll('.oap-stories__story')).findIndex(
        (x) => x.id === id
      );
    },
    selectStory(id) {
      let index = id;
      if (isNaN(index)) {
        index = this.findStoryIndexById(id);
      }

      this.isPaused = false;
      // pause the video before we switch to new story
      this.toggleVideoState('pause');
      this.difference += this.selectedIndex - index;
      this.selectedIndex = index;
      this.key = this.getLastViewedIndex(this.stories[this.selectedIndex]);
      // play the video when we do the switch
      this.toggleVideoState();
    },
    nextStory(index) {
      this.isPaused = false;
      if (this.hasEveryStoryEnded) {
        this.onEveryStoryEnd();
      } else if (this.hasEveryUserStoryEnded) {
        // late execution because of animation while switching user stories
        setTimeout(() => {
          this.toggleVideoState('pause');
          this.onEveryUserStoryEnd(index);
        }, 0);
      } else {
        this.stories[this.selectedIndex].data[this.key].isViewed = true;
        this.key++;
      }

      this.reset();
    },
    previousStory() {
      this.isPaused = false;
      if (this.selectedIndex <= 0 && this.key <= 0) {
        this.key = 0;
      } else if (this.key <= 0) {
        // late execution because of animation while switching user stories
        setTimeout(() => {
          this.toggleVideoState('pause');
          this.difference++;
          this.selectedIndex--;
          this.key = this.getLastViewedIndex(this.stories[this.selectedIndex]);
        }, 0);
      } else {
        this.key--;
        this.stories[this.selectedIndex].data[this.key].isViewed = false;
      }

      this.reset();
    },
    pauseStory() {
      this.toggleVideoState('pause');
      this.isPaused = true;
      this.pausedPercent = this.percent;
      clearInterval(this.progress);
      clearInterval(this.interval);
      this.newDuration = this.duration - (this.pausedPercent * this.duration) / 100;
    },
    playStory() {
      this.toggleVideoState();
      this.isPaused = false;
      this.play();
    },
    toggleVideoVolume() {
      if (this.isActiveStoryVideo && this.isEnabledProxy) {
        const activeMedia = this.getActiveMedia();
        activeMedia.muted = !activeMedia.muted;
        this.isMuted = activeMedia.muted;
      }
    },
    toggleVideoState(state = 'play') {
      if (this.isActiveStoryVideo && this.isEnabledProxy) {
        const activeMedia = this.getActiveMedia();
        activeMedia[state]();
      }
    },
    autoplay() {
      if (this.hasEveryStoryEnded) {
        this.onEveryStoryEnd();
      } else if (this.hasEveryUserStoryEnded) {
        this.onEveryUserStoryEnd(this.selectedIndex);
      } else {
        this.onCurrentStoryEnd(this.selectedIndex);
      }
      this.reset();
    },
    play() {
      this.timer = new Date().getTime();
      this.progress = setInterval(() => {
        let time = new Date().getTime();
        if (this.newDuration > 0) {
          this.percent =
            this.pausedPercent + Math.floor((100 * (time - this.timer)) / this.duration);
        } else {
          this.percent = Math.floor((100 * (time - this.timer)) / this.duration);
        }
      }, this.duration / 100);
      if (this.newDuration > 0) {
        this.interval = setInterval(this.autoplay, this.newDuration);
      } else {
        this.interval = setInterval(this.autoplay, this.duration);
      }
    },
    calculateTransform(index) {
      // mediaWidth = 415 + GAP
      const mediaWidth = 500;
      if (this.selectedIndex - index === -1 || this.selectedIndex - index === 1) {
        return mediaWidth * (index + this.difference);
      }
      if (index > this.selectedIndex) {
        return 0.5 * (mediaWidth + mediaWidth * (index + this.difference));
      } else {
        return Math.abs((mediaWidth - mediaWidth * (index + this.difference)) * 0.5) * -1;
      }
    },
    onTouchStart(event) {
      this.touchStartX = event.changedTouches[0].screenX;
      this.pauseStory();
    },
    onTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].screenX;
      this.handleGesture();
    },
    isSelectedIndexValid(state) {
      if (state === 'next') {
        return this.selectedIndex < this.stories.length - 1;
      }
      return this.selectedIndex - 1 >= 0;
    },
    handleGesture() {
      if (this.touchEndX < this.touchStartX && this.isSelectedIndexValid('next')) {
        this.selectStory(this.selectedIndex + 1);
      }

      if (this.touchEndX > this.touchStartX && this.isSelectedIndexValid('previous')) {
        this.selectStory(this.selectedIndex - 1);
      }

      if (this.touchEndX === this.touchStartX) {
        this.playStory();
      }
    },
    reset() {
      this.percent = 0;
      clearInterval(this.interval);
      clearInterval(this.progress);
      this.newDuration = 0;
      this.play();
      // wait to open the story experience
      setTimeout(this.toggleVideoState, 0);
    },
    readAndWriteToLocalStorage(index) {
      this.selectedIndexes.push(...readFromLocalStorage(VIEWED_INDEXES_STORAGE_KEY), index);
      writeToLocalStorage(VIEWED_INDEXES_STORAGE_KEY, this.selectedIndexes);
    },
  },
};
</script>
