<template>
  <div v-if="product !== null" class="productTile hasTextCentered">
    <div class="productTile__wrapper">
      <div class="productTile__imageWrapper">
        <MediaImage
          :filename="product.image.fileName"
          :alt="product.image.alt"
          :resolutions="product.image.resolutions"
          :fallback-size="product.image.fallbackSize"
          :sizes="product.image.sizes"
          :aria-hidden="true"
          :format="product.image.format"
        >
        </MediaImage>
      </div>
      <div class="productTile__infoWrapper">
        <p class="productTile__brandName">{{ product.brand }}</p>
        <p class="productTile__productName">{{ product.name }}</p>
      </div>
    </div>
    <div class="productTile__ratings">
      <template v-if="product.ratingActivated">
        <div class="ratingsList">
          <span
            v-for="rating in $options.settings.ratings"
            :key="rating"
            class="ratingsList__star"
            :class="{
              '-isSelected': product.starNumber >= rating && product.starNumber != null,
            }"
          >
            <svg
              width="13px"
              height="13px"
              viewBox="0 0 42 40"
              aria-hidden="true"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g stroke="none" stroke-width="1" fill-rule="evenodd">
                <path
                  d="M32.1840261,37.568991 L28.3704261,25.1809655 L39.231509,16.2587574 L25.798253,16.1621556 L21,2.93285568 L16.201747,16.1621556 L2.76849099,16.2587574 L13.6295739,25.1809655 L9.81597389,37.568991 L21,29.957571 L32.1840261,37.568991 Z"
                  stroke-width="2"
                />
              </g>
            </svg>
          </span>
        </div>
        <div class="ratingsAverage">
          {{ product.starNumber === null ? 0 : product.starNumber }}/{{ $options.settings.ratings }}
        </div>
      </template>
    </div>
    <div class="productTile__priceWrapper">
      <p class="productTile__price">
        <a :href="product.url" target="_blank">{{ product.label }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import MediaImage from '../../../../MediaImage/code/Scripts/components/MediaImage.vue';

export default {
  name: 'HaircolorTreatProductTile',

  components: {
    MediaImage,
  },

  props: {
    product: { type: Object },
  },

  settings: {
    ratings: 5,
  },
};
</script>
