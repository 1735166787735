<template>
  <div
    class="oap-stories__slide-controls"
    @touchstart="$emit('touch-start', $event)"
    @touchend="$emit('touch-end', $event)"
  >
    <button
      class="oap-stories__icon oap-stories__icon--prev-story"
      @click="$emit('previous-story')"
    >
      <span class="is-sr-only">{{ translations.next }}</span>
      <svg aria-hidden="true" class="icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow-prev"></use>
      </svg>
    </button>
    <button class="oap-stories__icon oap-stories__icon--next-story" @click="$emit('next-story')">
      <span class="is-sr-only">{{ translations.previous }}</span>
      <svg aria-hidden="true" class="icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow-next"></use>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'OapStoriesSlideControls',
  props: {
    translations: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
