<template>
  <div class="haircolorTreatResult_outer">
    <div class="haircolorTreatResult">
      <slot name="header" />
      <template v-if="!loaded">
        <slot name="loading" />
      </template>
      <template v-else>
        <div class="haircolorTreatResult__container -absolute">
          <ul class="d-flex">
            <li
              v-for="(item, index) in getSelectedProduct"
              v-if="item.starProduct"
              :key="item.ean"
              @click="getnewColors(item, index, 'star'), pushTaggingInfo(item)"
            >
              <HaircolorTreatProductTile :product="item" />
            </li>
            <li
              v-for="(item, index) in getNonStarProduct"
              v-if="!item.starProduct && starProductCount"
              :key="item.ean"
              class="nonStarProduct"
              :class="item.parentItemID"
              @click="getnewColors(item, index, 'noStar'), pushTaggingInfo(item)"
            >
              <HaircolorTreatProductTile :product="item" />
            </li>
            <li
              v-for="(item, index) in getNonSelectedProduct"
              v-if="!item.starProduct && !starProductCount"
              :key="item.ean"
              class="nonStarProduct"
              :class="item.parentItemID"
              @click="getnewColors(item, index, 'noStar'), pushTaggingInfo(item)"
            >
              <HaircolorTreatProductTile :product="item" />
            </li>
          </ul>
        </div>
        <div class="haircolorTreatResult__container">
          <slot name="shade-section" />
          <slot name="color-dropdown-treat" :colors="getnewColors(1, 1, 1)" />
          <slot name="color-selector" :colors="getnewColors(1, 1, 1)" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import HaircolorTreatProductTile from './HaircolorTreatProductTile.vue';
import { fetchData } from '../../../../../Foundation/Core/code/Scripts/mixins/fetchData/fetchData';
import {
  VTO_HAIRCOLOR_URL_PARAM,
  VTO_TRIGGER_URL_PARAM,
} from '../../../../VirtualTryOn/code/Scripts/settings';

const PRODUCT_LINE_REFERENCE_EAN_URL_PARAM = 'referenceEan';
const SELECTED_EAN_URL_PARAM = 'ean';

export default {
  name: 'HaircolorTreatResult',

  components: {
    HaircolorTreatProductTile,
  },

  props: {
    referenceEan: { type: String, required: true },
    haircolorDiagApiUrl: { type: String, required: true },
    productDimension49: { type: String, required: true, default: undefined },
  },

  data() {
    return {
      selectedEan: null,
      selectedParentID: null,
      selectedNonStarParentID: null,
      productsMap: null,
      productsMapSelected: null,
      starProductCount: false,
      starProductCounts: null,
      loaded: false,
      onLoaddataLayervalue: true,
      haircolorFamily: new URL(document.location).searchParams.get(VTO_HAIRCOLOR_URL_PARAM),
    };
  },

  computed: {
    getSelectedProduct() {
      return this.productsMap;
    },

    getNonStarProduct() {
      const filteredvaluesNew = [];
      const filteredData = this.filteredData();

      filteredData.map((product) => {
        if (
          Object.values(this.productsMap).filter((item) => product.parentItemID == item.itemID)
            .length === 0
        ) {
          filteredvaluesNew.push(product);
        }
      });

      return filteredvaluesNew;
    },

    getNonSelectedProduct() {
      return this.filteredData();
    },

    getVtoUrl() {
      const url = new URL(this.getSelectedProduct.url, window.location.origin);
      url.searchParams.append(VTO_TRIGGER_URL_PARAM, 'true');
      url.searchParams.append(VTO_HAIRCOLOR_URL_PARAM, this.haircolorFamily);
      return url;
    },
  },

  mounted() {
    const url = new URL(this.haircolorDiagApiUrl, window.location.origin);

    url.searchParams.append(VTO_HAIRCOLOR_URL_PARAM, this.haircolorFamily);
    url.searchParams.append(PRODUCT_LINE_REFERENCE_EAN_URL_PARAM, this.referenceEan);
    fetchData(url, {}, this.setData);

    // Listeners
    eventBus.on(`color-dropdown-treat::change`, this.selectEan);
  },

  methods: {
    filteredData() {
      // TODO: THIS NEEDS TO BE CHECKED FROM THE DEVELOPER THAT HAVE CREATED
      const filteredValues = [];
      Object.values(this.productsMap).map((product) => {
        if (
          filteredValues.filter((item) => item.parentItemID == product.parentItemID).length === 0
        ) {
          filteredValues.push(product);
        }
      });
      return filteredValues;
    },

    setData(data) {
      this.productsMap = data;
      this.selectEan(Object.keys(this.productsMap)[0]);

      this.productsMapSelected = Object.values(this.productsMap).map(
        ({ itemID, starProduct, parentItemID }) => {
          return {
            itemID,
            starProduct,
            parentItemID,
          };
        }
      );

      for (let i = 0; i < this.productsMapSelected.length; i++) {
        if (this.productsMapSelected[i].starProduct) {
          this.selectedParentID = this.productsMapSelected[i].itemID;
          this.starProductCount = true;
          break;
        } else if (!this.productsMapSelected[0].starProduct) {
          this.selectedNonStarParentID = this.productsMapSelected[0].parentItemID;
        }
      }

      for (var i = 0; i < this.productsMapSelected.length; i++) {
        if (!this.productsMapSelected[i].starProduct) {
          this.starProductCounts = i;
        }
      }

      if (!this.starProductCount) {
        this.selectedParentID = this.selectedNonStarParentID;
      }

      this.loaded = true;
    },

    getnewColors(item, index, star) {
      if (item != '1') {
        this.selectedEan = item.ean;
        this.selectedParentID = item.itemID;
        if (!this.starProductCount) {
          this.selectedParentID = item.parentItemID;
        }
      }

      if (star == 'noStar') {
        this.selectedParentID = item.parentItemID;
      }

      const firstItem = Object.values(this.productsMap).map(({ name, ean }) => {
        return {
          name,
          ean,
        };
      });

      if (this.onLoaddataLayervalue) {
        this.onloadDataLayer(firstItem[0].name, this.selectedEan);
      }

      this.onLoaddataLayervalue = false;

      const colors = Object.values(this.productsMap).map(
        ({
          brand,
          dimension38,
          ean,
          hex,
          isDark,
          name,
          price,
          swatch,
          url,
          value,
          vtoActivated,
          parentItemID,
          itemID,
        }) => {
          return {
            brand,
            dimension38,
            hex,
            isDark,
            name,
            price,
            swatch,
            url,
            value,
            vtoActivated,
            ean,
            parentItemID,
            itemID,
            selected: ean === this.selectedEan,
            sameitem: parentItemID === this.selectedParentID,
            activeitem: itemID === this.selectedParentID,
          };
        }
      );
      return colors.length > 0 ? colors : false;
    },

    onloadDataLayer(itemNameOn, ean) {
      const taggingObj = {
        event: 'nievent',
        category: 'diagnostic::haircolor',
        action: 'results',
        label: itemNameOn + '::' + ean,
      };
      window.dataLayer.push(taggingObj);
    },

    pushTaggingInfo(color) {
      const taggingObj = {
        event: 'uaevent',
        eventCategory: 'Ecommerce',
        eventAction: 'Product Detail|Variant selection',
        eventLabel: `${color.name}::${color.ean}::${color.value}`.toLowerCase(),
        event_name: 'view_item',
        event_detail: `variant selection`,
        product_info: `${color.name}::${color.ean}::${color.value}`.toLowerCase(),
        ecommerce: {
          detail: {
            actionField: {
              list: 'detail',
              action: 'detail',
            },
            products: [
              {
                brand: 'OAP',
                category: color.brand,
                dimension36: `${color.value}`.toLowerCase(),
                dimension38: color.dimension38,
                dimension48: `${color.ean}`.toLowerCase(),
                dimension49: this.productDimension49,
                id: `${color.ean}`.toLowerCase(),
                name: color.name,
                price: color.price,
              },
            ],
          },
        },
      };

      /* istanbul ignore else */
      if (color.dimension35) {
        taggingObj.products[0].dimension35 = color.dimension35;
      }

      window.dataLayer.push(taggingObj);
    },

    selectEan(ean) {
      const url = new URL(document.location);

      this.selectedEan = ean;
      url.searchParams.set(VTO_HAIRCOLOR_URL_PARAM, this.haircolorFamily);
      url.searchParams.set(SELECTED_EAN_URL_PARAM, this.selectedEan);

      history.replaceState(null, '', url.toString());
    },
  },
};
</script>
