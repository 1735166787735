<template>
  <div :class="['instagram-embed component']">
    <LoadScript
      v-if="loadInstagramScript"
      :src="instagramApi"
      name="instagram"
      class="instagram-load-script"
      @loaded="hideScript"
    />
    <slot></slot>
  </div>
</template>

<script>
/* eslint-disable no-undef*/
import LoadScript from '../../../../../Foundation/Core/code/Scripts/loadscript/loadscript.vue';
import { intersectionViewportObserver } from '../../../../../Foundation/Core/code/Scripts/utilities/intersectionViewportObserver';

export default {
  name: 'Instagram',
  components: {
    LoadScript,
  },
  props: {
    instagramApi: { type: String, required: false },
  },
  data() {
    return {
      loadInstagramScript: false,
    };
  },
  mounted() {
    intersectionViewportObserver(this.$el, { checkIsVisible: false }).then(() => {
      this.loadInstagramScript = true;
    });
  },
  methods: {
    hideScript() {
      this.loadInstagramScript = false;
    },
  },
};
</script>
