<template>
  <fieldset class="generic-options generic-form__component" :class="computedClasses">
    <template v-if="displayType === 2">
      <GenericDropdownContainer
        :id="id"
        :input-label="label"
        :disabled="disabled || readonly"
        :required="required"
        :readonly="readonly"
        :input-name="name"
        :type="type"
        :selected-options="getOptionsLabel"
        :multi-select="true"
        :has-errors="computedErrors?.length !== 0"
        :invalid="materialHasErrors"
        :description-id="ariaDescriptionIds"
        :helper-text="helpText"
        :placeholder="placeholder"
        @handle-validation="handleValidation"
        @handle-input="handleModelInput"
      >
        <div
          v-for="(option, index) in options"
          :id="`input-block-${index}`"
          :key="index"
          class="input-block"
          role="option"
          :disabled="disabled || readonly"
          :required="required"
          :aria-invalid="materialHasErrors"
          :aria-checked="isSelected(index)"
        >
          <div class="label-wrapper">
            <div
              :id="`option-${id}-${index}`"
              :name="`option-${id}`"
              :data-value="index"
              class="checkbox-input"
              :role="type"
              tabindex="-1"
            />
            <span class="checkmark"></span>
            <span class="checkbox-input__text">{{ option }}</span>
          </div>
        </div>
      </GenericDropdownContainer>
    </template>
    <template v-else>
      <legend v-if="$slots.default" :key="legendKey" class="generic-form__label">
        <slot></slot>
        <span v-if="required" class="required" aria-hidden="true">*</span>
      </legend>
      <div v-for="(field, index) in options" :key="index" class="input-block">
        <label class="label-wrapper" :for="`option-${id}-${index}`">
          <input
            :id="`option-${id}-${index}`"
            :name="`option-${id}`"
            :data-value="index"
            class="checkbox-input"
            :type="type"
            :disabled="disabled || readonly"
            :required="required"
            :aria-invalid="materialHasErrors"
            :aria-describedby="ariaDescriptionIds"
            @focus="handleFocus(true)"
            @blur="handleFocus(false)"
            @input="handleModelInput($event, false)"
            @keydown.space.prevent="handleModelInput($event, true)"
            @keydown.enter.prevent="handleModelInput($event, true)"
          />
          <span class="checkmark"></span>
          <span class="checkbox-input__text">{{ options[index] }}</span>
        </label>
      </div>
    </template>

    <div v-show="hasDescription" :id="`description-${id}`" class="generic-options__description">
      <div class="generic-errors">
        <div v-for="(error, key) in computedErrors" :key="key" class="generic-errors__item">
          {{ error }}
        </div>
      </div>
      <slot name="helperText"></slot>
    </div>
  </fieldset>
</template>

<script setup>
defineEmits(['update:modelValue', 'input']);
</script>

<script>
import GenericDropdownContainer from './generic-dropdown-container.vue';

export default {
  name: 'GenericOptions',
  components: {
    GenericDropdownContainer,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [Array, String],
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    helpText: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: [Array, String],
      default: null,
    },
    options: {
      type: Object,
      default: () => {},
    },
    displayType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      itemsCopy: [],
      focus: false,
      hadFocus: false,
      valid: true,
      started: false,
      legendKey: 0,
    };
  },
  computed: {
    ariaDescriptionIds() {
      return this.hasDescription ? `description-${this.id}` : null;
    },
    hasDescription() {
      return this.errorMessages || this.$slots.helperText;
    },
    computedErrors() {
      return typeof this.errorMessages === 'string' ? [this.errorMessages] : this.errorMessages;
    },
    materialHasErrors() {
      return Boolean(
        (this.hadFocus && !this.valid) || (this.errorMessages && this.errorMessages.length)
      );
    },
    computedClasses() {
      return {
        'generic--active': this.focus,
        'generic--disabled': this.disabled,
        'generic--readonly': this.readonly,
        'generic--inline': this.displayType === 0 && this.type !== 'radio',
        'generic--nested': this.displayType === 2,
        'generic--has-errors': this.materialHasErrors,
        'generic--invisible-label': !this.isLabelVisible,
      };
    },
    isLabelVisible() {
      return Boolean(!this.isEmpty(this.$slots.default));
    },
    getOptionsLabel() {
      if (!this.modelValue) return [];
      const selectedLabels = [];
      let selectedOptions;
      if (typeof this.modelValue === 'string') {
        selectedOptions = this.modelValue?.split(',');
      } else {
        selectedOptions = Array.from(this.modelValue);
      }
      selectedOptions.forEach((selected) => {
        Object.keys(this.options).forEach((option) => {
          if (option === selected) selectedLabels.push(this.options[option]);
        });
      });
      return selectedLabels;
    },
    notCheckedYet() {
      return this.type === 'checkbox' && !this.modelValue?.length;
    },
  },
  mounted() {
    if (this.modelValue) this.handleDefaultValue();
    this.legendKey++;
  },
  methods: {
    handleDefaultValue() {
      let selectedValues;
      if (this.type === 'radio') {
        selectedValues = [this.modelValue];
      } else {
        selectedValues = Array.from(this.modelValue);
      }

      Object.keys(this.options).forEach((option) => {
        selectedValues.forEach((value) => {
          if (option === value) {
            if (this.displayType === 2) {
              document.getElementById(`option-${this.id}-${option}`).classList.add('-checked');
            } else {
              document.getElementById(`option-${this.id}-${option}`).checked = true;
            }
          }
        });
      });
      this.itemsCopy = selectedValues;
      this.$emit('update:modelValue', selectedValues.join(','));
    },
    handleModelInput(event, isKeyboardEvent) {
      let target;
      let isChecked;
      if (this.displayType === 2) {
        target = this.getTarget(event.target, isKeyboardEvent);
        target?.classList.toggle('-checked');
        isChecked = target?.classList.contains('-checked');
      } else {
        target = event.target;
        if (isKeyboardEvent && this.type !== 'radio') target.checked = !target.checked;
        isChecked = target.checked === true;
      }

      /* istanbul ignore next */
      if (!this.started) {
        this.itemsCopy = this.modelValue ? this.modelValue.split(',') : [];
      }

      if (this.type == 'radio' || this.notCheckedYet) {
        this.itemsCopy = [];
      }

      this.handleCheckedOptions(target, isChecked);
      this.$emit('update:modelValue', this.getSelectedOptions());
      this.$emit('input');
      this.handleValidation();
      this.started = true;
    },
    getTarget(eventTarget, isKeyboardEvent) {
      let optionKey;
      let inputBlock;
      const fieldset = eventTarget.closest('fieldset');
      if (isKeyboardEvent) {
        const listBox = fieldset.querySelector('.generic-select__listbox');
        const focusedOption = listBox.querySelector('.-focused');
        return focusedOption.querySelector('.checkbox-input');
      } else {
        if (eventTarget.classList.contains('input-block')) {
          inputBlock = eventTarget;
        } else {
          inputBlock = eventTarget.closest('.input-block');
        }
        const rawId = inputBlock?.getAttribute('id');
        const result = rawId?.match(/input-block-(.*)/);
        optionKey = result ? result[1] : '';
        return fieldset.querySelector(`#option-${this.id}-${optionKey}`);
      }
    },
    getSelectedOptions() {
      return this.itemsCopy.length > 1 ? this.itemsCopy.join(',') : this.itemsCopy[0] || '';
    },
    handleCheckedOptions(target, isChecked) {
      if (isChecked) {
        this.itemsCopy.push(target?.dataset.value);
      } else {
        const index = this.itemsCopy.indexOf(target?.dataset.value);

        if (index >= 0) {
          this.itemsCopy.splice(index, 1);
        }
      }
    },
    handleFocus(focused) {
      this.focus = focused;
      this.hadFocus = true;
    },
    handleValidation() {
      this.valid = !this.required || this.itemsCopy.length > 0;
    },
    reset() {
      this.hadFocus = false;
      this.valid = true;
      this.focus = false;
    },
    isEmpty(value) {
      return typeof value === 'undefined' || value == null;
    },
    isSelected(value) {
      return this.modelValue?.includes(value);
    },
  },
};
</script>
