<template>
  <div class="oap-stories__story-item">
    <div
      class="oap-stories__overlay"
      @mousedown.self="pause"
      @mouseup.self="play"
      @touchstart.self="touchStart($event)"
      @touchend.self="touchEnd($event)"
    ></div>
    <video
      v-if="currentStory.type === 'video'"
      class="oap-stories__media"
      :src="currentStory.mediaUrl"
      muted
    ></video>
    <iframe
      v-else-if="currentStory.type === 'post'"
      class="oap-stories__media"
      :src="formattedPostUrl"
      title="Instagram post"
    ></iframe>
    <img v-else class="oap-stories__media" :src="currentStory.mediaUrl" alt="" />
    <slot name="shop-now" />
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';

export default {
  name: 'OapStoriesItem',
  props: {
    currentStory: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formattedPostUrl() {
      return `${this.currentStory.mediaUrl}embed`;
    },
  },
  methods: {
    play() {
      eventBus.emit('play-story');
    },
    pause() {
      eventBus.emit('pause-story');
    },
    touchStart(event) {
      eventBus.emit('touch-start', event);
    },
    touchEnd(event) {
      eventBus.emit('touch-end', event);
    },
  },
};
</script>
