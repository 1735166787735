<template>
  <div class="likecontainer">
    <button
      class="like__btn animated"
      @click="
        likecounter(likecountin);
        likecache();
        getTaggingInfo();
      "
    >
      <span class="like-button"></span>
      <span v-if="likecountin == 0" class="like__number"></span>
      <span v-else class="like__number">{{ likecountin }}</span>
    </button>
  </div>
</template>

<script>
import { AnalyticsHandler } from '@Foundation';

const likeButtonClass = '.like__btn';

export default {
  name: 'Likebutton',
  props: {
    likecount: { type: Number, required: true },
  },
  data() {
    return {
      likecountin: this.likecount,
      itemsArticle: [],
    };
  },
  mounted() {
    let itemid = document.getElementById('ArticleId').value;
    document.querySelector(likeButtonClass).setAttribute('id', itemid);
    var articlelike = JSON.parse(localStorage.getItem('articlelike'));

    var numberOfChildren = articlelike && articlelike.length;
    if (numberOfChildren > 0) {
      for (var i = 0; i < articlelike.length; i++) {
        if (itemid == articlelike[i]) {
          document.getElementById(articlelike[i]).classList.add('active_like');
        }
        this.itemsArticle.push(articlelike[i]);
      }
    }

    var idlists = itemid + ',';

    fetch(`/api/sitecore/article/GetLikeCountList?&idList=${idlists}`)
      .then((response) => response.json())
      .then((jsonData) => {
        var hideableSections = document.querySelectorAll(likeButtonClass);
        for (var i = 0; i < hideableSections.length; i++) {
          var allarticleIds = hideableSections[i].getAttribute('id');

          if (jsonData.list[i].likeCount <= 0) {
            document
              .getElementById(allarticleIds)
              .getElementsByClassName('like__number')[0].innerHTML = '';
          } else {
            document
              .getElementById(allarticleIds)
              .getElementsByClassName('like__number')[0].innerHTML = jsonData.list[i].likeCount;
          }
        }
      })
      .catch((e) => console.error(e));
  },
  methods: {
    likecounter: function () {
      var element = document.querySelector(likeButtonClass);
      var likeCounts = element.getElementsByClassName('like__number')[0].innerHTML;
      if (element.classList.contains('active_like')) {
        likeCounts--;
        element.getElementsByClassName('like__number')[0].innerHTML = likeCounts;
        element.classList.remove('active_like');
        fetch(
          `/api/sitecore/article/UpdateLikeCount?operatorValue=-1&itemID=${
            document.getElementById('ArticleId').value
          }`
        )
          .then((response) => response.json())
          .catch((e) => console.error(e));
      } else {
        likeCounts++;
        element.classList.add('active_like');
        element.getElementsByClassName('like__number')[0].innerHTML = likeCounts;
        fetch(
          `/api/sitecore/article/UpdateLikeCount?operatorValue=1&itemID=${
            document.getElementById('ArticleId').value
          }`
        )
          .then((response) => response.json())
          .catch((e) => console.error(e));
      }
    },
    likecache() {
      const element = document.querySelector(likeButtonClass);
      var itemid = document.getElementById('ArticleId').value;
      if (element.classList.contains('active_like')) {
        this.itemsArticle.push(itemid);
      } else {
        const index = this.itemsArticle.indexOf(itemid);
        if (index > -1) {
          this.itemsArticle.splice(index, 1);
        }
      }
      localStorage.setItem('articlelike', JSON.stringify(this.itemsArticle));
    },
    getTaggingInfo() {
      var activeId = document.getElementById('articleHeader');
      var articletitle = activeId.innerHTML;
      const element = document.querySelector(likeButtonClass);
      if (element.classList.contains('active_like')) {
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'userActionEvent',
          ecommerce: 'undefined',
          category: 'content page',
          action: 'like::article',
          label: articletitle + this.likecountin,
        });
      } else {
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'userActionEvent',
          ecommerce: 'undefined',
          category: 'content page',
          action: 'unlike::article',
          label: articletitle + this.likecountin,
        });
      }
    },
  },
};
</script>
