<template>
  <div v-if="popup.enablePopup" class="popup__mask popup" :data-popupid="answerid">
    <div class="popup__wrapper">
      <div class="popup__container">
        <div class="popup__header">
          <h3 class="popup__title">{{ popup.title }}</h3>
          <button class="popup__close" @click="hidePopOver(), pushPopupAnalytics()">
            <svg role="img" aria-labelledby="close-alert-icon" class="icon">
              <title id="close-alert-icon">Close</title>
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
            </svg>
          </button>
        </div>
        <div class="popup__content scrollbarCustom">
          <div class="haircolorTreatAlert__topContainer">
            <img
              alt="L Oreal Paris"
              src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              :data-src="popup.logoImage.fileName"
              class="haircolorTreatAlert__logo lazyload"
            />
            <p class="haircolorTreatAlert__subTitle">
              {{ popup.subTitle }}
            </p>
          </div>
          <p class="haircolorTreatAlert__message">{{ popup.Text }}</p>
        </div>
        <button v-if="popup.shouldClose" class="popup__confirm" @click="hidePopOver()">
          <a :href="popup.ctaLink" target="_blank">{{ popup.ctaLabel }}</a>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';
export default {
  name: 'HaircolorTreatPopup',
  props: {
    answerid: { type: String, required: true },
    popup: { type: Object, required: true },
  },
  methods: {
    pushPopupAnalytics() {
      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'userActionEvent',
        category: 'diagnostic::haircolor',
        action: 'select::continue',
        label: 'warning message',
      });
    },

    hidePopOver() {
      let popupID = document.querySelectorAll('[data-popupid]');
      for (let i = 0; i <= popupID.length; i++) {
        if (popupID[i]) {
          /* istanbul ignore next */
          popupID[i].style.display = 'none';
        }
      }
    },
  },
};
</script>
