import GenericForm from './code/Scripts/Components/generic-form.vue';
import GenericInput from './code/Scripts/Components/generic-input.vue';
import GenericOptions from './code/Scripts/Components/generic-options.vue';
import GenericDatepicker from './code/Scripts/Components/generic-datepicker.vue';
import GenericSelect from './code/Scripts/Components/generic-select.vue';

export default {
  components: {
    GenericForm,
    GenericInput,
    GenericOptions,
    GenericDatepicker,
    GenericSelect,
  },
};
