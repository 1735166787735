<template>
  <div class="oap-stories__controls">
    <button @click="$emit('story-trigger')">
      <span class="is-sr-only">
        <template v-if="isPaused">{{ translations.play }}</template>
        <template v-else>{{ translations.pause }}</template>
      </span>
      <svg aria-hidden="true" class="icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="`#${pausedIcon}`"></use>
      </svg>
    </button>
    <button @click="$emit('toggle-volume')">
      <span class="is-sr-only">
        <template v-if="!isVideoStory">{{ translations.cannotBeMuted }}</template>
        <template v-else>
          <template v-if="isMuted">{{ translations.unmute }}</template>
          <template v-else>{{ translations.mute }}</template>
        </template>
      </span>
      <svg aria-hidden="true" class="icon">
        <use
          v-if="!isVideoStory"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xlink:href="#sound-not-available"
        ></use>
        <use v-else xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="`#${mutedIcon}`"></use>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'OapStoriesStoryControls',
  props: {
    isPaused: {
      type: Boolean,
      default: false,
    },
    isMuted: {
      type: Boolean,
      default: true,
    },
    isVideoStory: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    mutedIcon() {
      return this.isMuted ? 'sound-muted' : 'sound-available';
    },
    pausedIcon() {
      return this.isPaused ? 'play' : 'pause';
    },
  },
};
</script>
