<template>
  <div class="oap-routine-builder">
    <div class="oap-routine-builder__heading-area">
      <component :is="mainHeading.heading" v-if="mainHeading" class="oap-routine-builder__title">
        {{ mainHeading.text }}
      </component>
      <component
        :is="subHeading.heading"
        v-if="subHeading"
        class="oap-routine-builder__description"
      >
        {{ subHeading.text }}
      </component>
      <Switcher
        which="oap-routine-builder"
        :switch1="sections[0] ? sections[0].title : ''"
        :switch2="sections[1] ? sections[1].title : ''"
        panel1-wrapper-class="oap-routine-builder__card-area"
        panel2-wrapper-class="oap-routine-builder__card-area"
        :tagging-event="taggingEvent"
      >
        <template
          v-for="(section, i) in sections"
          #[getPanel(i)]
          :key="i"
          tabindex="-1"
          class="oap-routine-builder__card-area"
        >
          <div
            v-for="item in section.items"
            :key="`section-${item.product.id}`"
            class="oap-routine-builder__card-wrapper"
          >
            <OapCard
              :key="`card-${item.product.id}`"
              v-is-intersecting-x
              :type="item.product.type"
              :header-title="item.title"
              :item="item.product"
              :display-review-count="displayReviewCount"
              :layouts="layouts"
            ></OapCard>
            <table aria-label="details" class="oap-card-details-table -is-first-loading">
              <template v-for="(detail, k) in item.details">
                <tr v-if="detail" :key="k + '-title'">
                  <th scope="col" class="oap-card-details-table__title">{{ detail.title }}</th>
                </tr>
                <tr v-if="detail" :key="k + '-paragraph'">
                  <td class="oap-card-details-table__paragraph">{{ detail.description }}</td>
                </tr>
              </template>
            </table>
          </div>
        </template>
      </Switcher>
    </div>
  </div>
</template>

<script>
import Switcher from '../../../../Navigation/code/Scripts/components/switcher.vue';
import OapCard from '../../../../OapCard/code/Scripts/components/OapCard.vue';
import eventBus from '@loreal/eventbus-js';

export default {
  name: 'OapRoutineBuilder',
  components: {
    Switcher,
    OapCard,
  },
  props: {
    mainHeading: {
      type: Object,
      default: () => {},
    },
    subHeading: {
      type: Object,
      default: () => {},
    },
    sections: {
      type: Array,
      default: () => [],
    },
    layouts: {
      type: Array,
      default: () => [],
    },
    displayReviewCount: {
      type: Boolean,
      default: true,
    },
    taggingEvent: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.watchSwitcherStatus();
    setTimeout(() => {
      this.adjustCellsHeight();
    }, 200);
    setTimeout(() => {
      this.removeFirstLoadingClass();
    }, 300);
  },
  methods: {
    getPanel(i) {
      return 'panel' + (i + 1);
    },
    watchSwitcherStatus() {
      eventBus.on('switchPanel', () => {
        setTimeout(() => {
          this.adjustCellsHeight();
        }, 100);
      });
    },
    adjustCellsHeight() {
      const tables = document.querySelectorAll(
        '.navigation__panel.-active .oap-card-details-table'
      );

      tables.forEach((table) => {
        const rows = table.querySelectorAll('tr');
        rows.forEach((row) => (row.style.height = 'auto'));
      });

      let maxRowsLength = 0;
      tables.forEach((table) => {
        const rows = table.querySelectorAll('tr');
        if (rows.length <= maxRowsLength) return;
        maxRowsLength = rows.length;
      });

      for (let i = 0; i < maxRowsLength; i++) {
        let cellMaxHeight = 0;
        tables.forEach((table) => {
          const rows = table.querySelectorAll('tr');
          const currentCellHeight = rows[i] ? rows[i].offsetHeight : 0;
          if (currentCellHeight <= cellMaxHeight) return;
          cellMaxHeight = currentCellHeight;
        });

        tables.forEach((table) => {
          const rows = table.querySelectorAll('tr');
          const currentCell = rows[i];
          const newRow = document.createElement('tr');
          newRow.style.height = cellMaxHeight + 'px';
          const newCell = document.createElement('td');
          newCell.classList.add('oap-card-details-table__title');
          if (!currentCell && i === 0) {
            table.appendChild(newRow);
            newRow.appendChild(newCell);
          } else if (!currentCell && i !== 0) {
            const referenceCell = rows[i - 1];
            referenceCell.parentNode.insertBefore(newRow, referenceCell.nextSibling);
            newRow.appendChild(newCell);
          } else {
            currentCell.style.height = cellMaxHeight + 'px';
          }
        });
      }
    },
    removeFirstLoadingClass() {
      const loadedElements = document.querySelectorAll('.-is-first-loading');
      if (!loadedElements.length) return;
      loadedElements.forEach((element) => element.classList.remove('-is-first-loading'));
    },
  },
};
</script>
