<template>
  <div class="haircolorDiagTip">
    <p v-if="tip.title" class="haircolorDiagTip__title">{{ tip.title }}</p>
    <div class="rich-text" v-html="tip.text"></div>
  </div>
</template>

<script>
export default {
  name: 'HaircolorDiagTip',
  props: {
    tip: { type: Object, required: true },
  },
};
</script>
