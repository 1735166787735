/**
 * @see https://www.sitepoint.com/creating-accurate-timers-in-javascript/
 * @see https://stackoverflow.com/questions/29971898/how-to-create-an-accurate-timer-in-javascript
 */

export default class Timer {
  constructor(callback, ms = 1000) {
    this.target = performance.now(); // target time for the next frame
    this.interval = (1 / ms) * 1000; // the milliseconds between ticks
    this.callback = callback;
    this.stopped = false;

    this.tick(this);
  }

  tick(self) {
    if (self.stopped) return;

    const currentTime = performance.now();
    const currentTarget = self.target;
    const currentInterval = (self.target += self.interval) - currentTime;

    setTimeout(self.tick, currentInterval, self);
    self.callback(currentTime, currentTarget, self);
  }

  stop() {
    this.stopped = true;
  }
}
