<template>
  <ul class="oap-stories__time">
    <li v-for="(_, index) in dataLength" :key="index" class="oap-stories__time-item">
      <div class="oap-stories__time-fill" :style="setTimeStyle(index)"></div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'OapStoriesTime',
  props: {
    dataLength: {
      type: Number,
      default: 0,
    },
    currentStoryKey: {
      type: Number,
      default: 0,
    },
    percent: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    setTimeStyle(index) {
      return index === this.currentStoryKey
        ? `width: ${this.percent}%`
        : this.currentStoryKey > index
        ? `width:100%`
        : `width:0%`;
    },
  },
};
</script>
