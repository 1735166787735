<template>
  <div class="oap-eventful-text-input">
    <input
      v-model="text"
      type="text"
      :placeholder="placeholderText"
      required
      @keydown.enter="submit"
    />
    <button @click="submit">
      <svg role="img">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="svgIconId"></use>
      </svg>
    </button>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '@Foundation';
import { BEAUTY_GENIUS_TYPE_IN_TAGGING_EVENT } from '@Feature/OapBeautyGeniusWrapper/code/Scripts/settings';

const props = defineProps({
  placeholderText: { type: String, default: '' },
  iframeTriggerEventName: { type: String, default: 'beauty-genius-iframe:open' },
  svgIconId: { type: String, default: '#arrow-top-right' },
  analytics: { type: Object, default: () => {} },
});

const text = ref('');
let tag;

onMounted(async () => {
  tag = { ...props.analytics };

  eventBus.on(BEAUTY_GENIUS_TYPE_IN_TAGGING_EVENT, (event) => {
    if (event) {
      tag.event_name = event.payload.event_name;
      tag.action = event.payload.action;
    }
  });
});

function submit() {
  if (!text.value.length) return;

  eventBus.emit(props.iframeTriggerEventName, {
    payload: text.value,
  });

  if (props.analytics) {
    const url = new URL(`${props.analytics.link_url}/chat?prompt=${text.value}`).toString();
    tag.type = 'userActionEvent';
    tag.link_url = url;

    try {
      AnalyticsHandler.getAnalyticsHandler().push(tag);
    } catch (e) {
      /* istanbul ignore next */
      console.warn('Could not push to dataLayer', e);
    }
  }

  text.value = '';
}
</script>
