<template>
  <div class="haircolorDiagResult">
    <slot name="header" />
    <template v-if="!loaded">
      <slot name="loading" />
    </template>
    <template v-else>
      <haircolor-diag-product-tile
        v-if="getSelectedProduct"
        :product="getSelectedProduct"
        :product-querystring="queryStringVal"
      />
      <div class="haircolorDiagResult__container">
        <slot name="shade-section" />
        <slot v-if="getColors" name="color-dropdown" :colors="getColors" />
        <slot name="vto-cta" :href="getVtoUrl" :active="getSelectedProduct.vtoActivated" />
        <slot v-if="getColors" name="color-selector" :colors="getColors" />
      </div>
    </template>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import haircolorDiagProductTile from './haircolorDiagProductTile.vue';
import { fetchData } from '../../../../../Foundation/Core/code/Scripts/mixins/fetchData/fetchData';
import {
  VTO_HAIRCOLOR_URL_PARAM,
  VTO_TRIGGER_URL_PARAM,
} from '../../../../VirtualTryOn/code/Scripts/settings';

const PRODUCT_LINE_REFERENCE_EAN_URL_PARAM = 'referenceEan';
const SELECTED_EAN_URL_PARAM = 'ean';

export default {
  name: 'HaircolorDiagResult',

  components: {
    haircolorDiagProductTile,
  },

  props: {
    referenceEan: { type: String, required: true },
    haircolorDiagApiUrl: { type: String, required: true },
    queryStringVal: { type: String, required: false },
  },

  data() {
    return {
      selectedEan: null,
      productsMap: null,
      loaded: false,

      haircolorFamily: new URL(document.location).searchParams.get(VTO_HAIRCOLOR_URL_PARAM),
    };
  },

  computed: {
    getColors() {
      if (!this.productsMap || !this.selectedEan) return false;

      const colors = Object.values(this.productsMap).map(
        ({
          brand,
          dimension38,
          ean,
          hex,
          isDark,
          name,
          price,
          swatch,
          url,
          value,
          vtoActivated,
        }) => {
          return {
            brand,
            dimension38,
            hex,
            isDark,
            name,
            price,
            swatch,
            url,
            value,
            vtoActivated,
            ean,
            selected: ean === this.selectedEan,
          };
        }
      );

      return colors.length > 0 ? colors : false;
    },

    getSelectedProduct() {
      return this.productsMap && this.productsMap[this.selectedEan];
    },

    getVtoUrl() {
      const url = new URL(this.getSelectedProduct.url, window.location.origin);
      url.searchParams.append(VTO_TRIGGER_URL_PARAM, 'true');
      return url;
    },
  },

  mounted() {
    const url = new URL(this.haircolorDiagApiUrl, window.location.origin);

    url.searchParams.append(VTO_HAIRCOLOR_URL_PARAM, this.haircolorFamily);
    url.searchParams.append(PRODUCT_LINE_REFERENCE_EAN_URL_PARAM, this.referenceEan);
    fetchData(url, {}, this.setData);

    // Listeners
    eventBus.on(`ColorDropdown::change`, this.selectEan);
  },

  methods: {
    setData(data) {
      this.productsMap = data;
      this.selectEan(Object.keys(this.productsMap)[0]);
      this.loaded = true;
    },

    selectEan(ean) {
      const url = new URL(document.location);

      this.selectedEan = ean;
      url.searchParams.set(VTO_HAIRCOLOR_URL_PARAM, this.haircolorFamily);
      url.searchParams.set(SELECTED_EAN_URL_PARAM, this.selectedEan);

      history.replaceState(null, '', url.toString());
    },
  },
};
</script>
