<template>
  <div class="generic-input generic-form__component" :class="computedClasses">
    <input
      v-if="type !== 'textarea'"
      :id="id"
      ref="genericInput"
      :value="modelValue"
      class="generic-input__field"
      :type="type"
      :name="name"
      :aria-describedby="ariaDescriptionIds"
      :aria-invalid="materialHasErrors"
      :placeholder="placeholder"
      :disabled="disabled || readonly"
      :autocomplete="autocomplete"
      :required="required"
      :minlength="minlengthIfText"
      :maxlength="maxlengthIfText"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
      @input="handleModelInput"
      @keydown.prevent.stop.enter="() => null"
    />
    <textarea
      v-else
      :id="id"
      ref="genericInput"
      :value="modelValue"
      class="generic-input__field"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled || readonly"
      :autocomplete="autocomplete"
      :aria-invalid="materialHasErrors"
      :aria-describedby="ariaDescriptionIds"
      :required="required"
      :minlength="minlengthIfText"
      :maxlength="maxlengthIfText"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
      @input="handleModelInput"
      @keydown.prevent.stop.enter="() => null"
    >
    </textarea>

    <template v-if="appendIcon?.enabled">
      <span v-if="type === 'email'" class="generic-input__icon -email" />
      <button
        v-if="type === 'password'"
        role="switch"
        class="generic-input__icon -password"
        :aria-label="passwordButtonAriaLabel"
        :aria-checked="type !== 'password'"
        @click.prevent="togglePassword"
        @keydown.self.prevent.stop.enter="togglePassword"
        @keydown.self.prevent.stop.space="togglePassword"
      />
    </template>

    <label v-show="isLabelVisible" class="generic-form__label" :for="id">
      <slot></slot><span v-if="required" class="required" aria-hidden="true">*</span>
    </label>

    <div v-show="hasDescription" :id="`description-${id}`">
      <div v-if="showErrors" class="generic-errors">
        <div v-for="(error, key) in computedErrors" :key="key" class="generic-errors__item">
          {{ error }}
        </div>
      </div>
      <slot name="helperText"></slot>
    </div>
  </div>
</template>

<script setup>
defineEmits(['update:modelValue']);
</script>

<script>
export default {
  name: 'GenericInput',
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    modelValue: {
      default: null,
      type: [Array, String],
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    step: {
      type: Number,
      default: null,
    },
    minlength: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: [Array, String],
      default: null,
    },
    appendIcon: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      interacted: false,
      focus: false,
      passwordButtonAriaLabel: this.appendIcon?.ariaLabel.primary,
    };
  },
  computed: {
    maxlengthIfText() {
      return this.maxlength && this.type === 'text' ? this.maxlength : null;
    },
    minlengthIfText() {
      return this.minlength && this.type === 'text' ? this.minlength : null;
    },
    ariaDescriptionIds() {
      return this.hasDescription ? `description-${this.id}` : null;
    },
    hasDescription() {
      return this.computedErrors || this.$slots.helperText;
    },
    computedErrors() {
      return typeof this.errorMessages === 'string' ? [this.errorMessages] : this.errorMessages;
    },
    materialHasErrors() {
      return Boolean(this.errorMessages && this.errorMessages.length);
    },
    computedClasses() {
      return {
        'generic--active': this.focus,
        'generic--disabled': this.disabled,
        'generic--readonly': this.readonly,
        'generic--has-errors': this.showErrors,
        'generic--raised': Boolean(
          this.focus || this.modelValue || (this.placeholder && !this.modelValue)
        ),
      };
    },
    isLabelVisible() {
      return Boolean(this.label != null && this.label !== '');
    },
    showErrors() {
      return this.materialHasErrors;
    },
  },
  methods: {
    handleModelInput(event) {
      this.$emit('update:modelValue', event.target.value);
    },
    handleFocus(focused) {
      if (!focused) this.interacted = true;

      this.focus = focused;
    },
    togglePassword(e) {
      const passwordInput = document.getElementById(this.id);
      if (passwordInput.type === 'password') {
        passwordInput.type = 'text';
        e.target.classList.add('-visible');
        e.target.setAttribute('aria-checked', 'true');
        this.passwordButtonAriaLabel = this.appendIcon.ariaLabel.secondary;
      } else {
        passwordInput.type = 'password';
        e.target.classList.remove('-visible');
        e.target.setAttribute('aria-checked', 'false');
        this.passwordButtonAriaLabel = this.appendIcon.ariaLabel.primary;
      }
    },
  },
};
</script>
